/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/@angular/material/checkbox/typings/index.ngfactory";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/material/checkbox";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./mat-checkbox-renderer.component";
var styles_MatCheckboxRendererComponent = [];
var RenderType_MatCheckboxRendererComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MatCheckboxRendererComponent, data: {} });
export { RenderType_MatCheckboxRendererComponent as RenderType_MatCheckboxRendererComponent };
export function View_MatCheckboxRendererComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "mat-checkbox", [["class", "mat-checkbox"], ["disabled", "true"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null]], null, null, i1.View_MatCheckbox_0, i1.RenderType_MatCheckbox)), i0.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.MatCheckbox]), i0.ɵdid(2, 8568832, null, 0, i3.MatCheckbox, [i0.ElementRef, i0.ChangeDetectorRef, i4.FocusMonitor, i0.NgZone, [8, null], [2, i3.MAT_CHECKBOX_CLICK_ACTION], [2, i5.ANIMATION_MODULE_TYPE]], { checked: [0, "checked"], disabled: [1, "disabled"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.params.value; var currVal_8 = "true"; _ck(_v, 2, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).id; var currVal_1 = null; var currVal_2 = i0.ɵnov(_v, 2).indeterminate; var currVal_3 = i0.ɵnov(_v, 2).checked; var currVal_4 = i0.ɵnov(_v, 2).disabled; var currVal_5 = (i0.ɵnov(_v, 2).labelPosition == "before"); var currVal_6 = (i0.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_MatCheckboxRendererComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-mat-checkbox-renderer", [], null, null, null, View_MatCheckboxRendererComponent_0, RenderType_MatCheckboxRendererComponent)), i0.ɵdid(1, 49152, null, 0, i6.MatCheckboxRendererComponent, [], null, null)], null, null); }
var MatCheckboxRendererComponentNgFactory = i0.ɵccf("app-mat-checkbox-renderer", i6.MatCheckboxRendererComponent, View_MatCheckboxRendererComponent_Host_0, {}, {}, []);
export { MatCheckboxRendererComponentNgFactory as MatCheckboxRendererComponentNgFactory };
