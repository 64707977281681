import { ProjectStoreModel } from '../project/project-store-model';

export const ADD_PROJECT = 'ADD_PROJECT';
export const REMOVE_PROJECT = 'REMOVE_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const SAVE_PROJECT = 'SAVE_PROJECT';
export const LOAD_PROJECT = 'LOAD_PROJECT';
export const CLEAR_ALL_PROJECT = 'CLEAR_ALL_PROJECT';
export const REMOVE_HASCHANGESWITHOUTSAVINGS = 'REMOVE_HASCHANGESWITHOUTSAVINGS';

export function projectReducer(allProjects: ProjectStoreModel[], action) {
    switch (action.type) {
        case ADD_PROJECT:
            const index = allProjects.map(project => project.id).indexOf(action.payload.id);
            if (index === -1) {
                return [...allProjects, action.payload];
            } else {
                const newProject = [...allProjects];
                newProject[index] = action.payload;
                return newProject;
            }
            break;
        case REMOVE_PROJECT:
            return allProjects.filter(project => {
                return project.url !== action.payload.url;
            });
            break;
        case CLEAR_ALL_PROJECT:
            return [];
            break;
        case UPDATE_PROJECT:
            allProjects.map(project => {
                if (project.id === action.payload.id) {
                    project.project = action.payload.project ? action.payload.project : project.project;
                    project.hasChangesWithoutSaving = action.payload.hasChangesWithoutSaving;
                    return project;
                }
            });
            return allProjects;
            break;
        case SAVE_PROJECT:
            allProjects.map(project => {
                if (project.id === action.payload.id) {
                    project.project = action.payload.project ? action.payload.project : project.project;
                    project.hasChangesWithoutSaving = action.payload.hasChangesWithoutSaving;
                    return project;
                }
            });
            return allProjects;
            break;
        case LOAD_PROJECT:
            allProjects.map(project => {
                if (project.id === action.payload.id) {
                    return project;
                }
            });
            return allProjects;
            break;
        case REMOVE_HASCHANGESWITHOUTSAVINGS:
            allProjects.map(project => {
                if (project.id === action.payload.id) {
                    project.hasChangesWithoutSaving = action.payload.hasChangesWithoutSaving;
                    return project;
                }
            });
            break;
        default:
            return allProjects;
    }
}
