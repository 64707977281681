import * as tslib_1 from "tslib";
import { Injectable } from '../../../node_modules/@angular/core';
import { ToastrService } from '../../../node_modules/ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '../../../node_modules/@angular/common/http';
import { environment } from '../../environments/environment';
import { Port } from '@port/port';
let MasterdataService = class MasterdataService {
    constructor(toastr, translate, authService, httpClient) {
        this.toastr = toastr;
        this.translate = translate;
        this.authService = authService;
        this.httpClient = httpClient;
    }
    loadMasterdata() {
        return this.httpClient.get(`${environment.apiBaseUrl}/masterdata`, { withCredentials: true });
    }
    loadMasterdataPromise() {
        return this.httpClient
            .get(`${environment.apiBaseUrl}/masterdata`, {
            withCredentials: true,
        })
            .toPromise()
            .then(data => {
            this.masterdata = data;
            return data;
        });
    }
    getMasterdata() {
        if (this.masterdata === undefined) {
            this.loadMasterdataPromise();
        }
        return this.masterdata;
    }
    getPortWithId(portId) {
        return this.masterdata.ports.find(port => port.id === portId) || new Port();
    }
};
MasterdataService = tslib_1.__decorate([
    Injectable(),
    tslib_1.__metadata("design:paramtypes", [ToastrService,
        TranslateService,
        AuthService,
        HttpClient])
], MasterdataService);
export { MasterdataService };
