<a id="navbarDropdownMenu" class="faa-parent animated-hover" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <!-- Bell -->
    <i class="notifi-bell material-icons faa-slow faa-ring">
        notifications
    </i>
    <!-- circle about bell -->
    <span class="badge badge-pill badge-info notifyBadge">{{todoCount}}
    </span>
    <!-- <span class="fa fa-circle fa-notify text-success dot-note"></span> -->
</a>
<!-- DropDown -->
<div class="dropdown-menu rounded-0 dropdown-menu-right dropdown-menu-lg p-0" aria-labelledby="navbarDropdownMenu">
    <!-- Header -->
    <div class="dropdown-header bg-success d-flex justify-content-between">
        <span *ngIf="todoNotification; then showTodos; else showNoTodos">
        </span>
        <ng-template #showNoTodos>
            <span class="align-self-center">
                {{ 'todo.notification.noTodos' | translate}}
            </span>
        </ng-template>
        <ng-template #showTodos>
            <span class="align-self-center">
                {{todoCount}} {{ 'todo.notification.todos' | translate}}
            </span>
        </ng-template>
    </div>
    <!-- Items -->
    <div *ngFor="let todo of todoNotification?.todos; let i = index" >
            <a *ngIf="i < todoNotificationSize" [routerLink]="['/todos']" class="dropdown-item  d-flex pt-3">
                <div class="icon-msg ml-2 mr-2">
                    <span class="align-self-start d-block ">
                        {{todo.text}}
                    </span>
                    <small>{{ 'todo.notification.deadline' | translate}} {{todo.deadline | date: 'yyy-MM-dd'}}</small>
                </div>
            </a>
    </div>
    <!-- Footer -->
    <div *ngIf="todoNotification?.total > todoNotificationSize" class="dropdown-header bg-success d-flex justify-content-between">
        <a [routerLink]="['/todos']" class="align-self-center">
            {{ 'todo.notification.viewAll' | translate}}
        </a>
    </div>

</div>
