import { Component, EventEmitter, Input, Output, ViewChild, TemplateRef, ViewContainerRef } from '@angular/core';
import { Tab } from '../tab';
import { Router } from '@angular/router';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { OverlayRef, Overlay } from '@angular/cdk/overlay';
import { Subscription, fromEvent } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
    selector: 'app-tabs-list',
    templateUrl: './tab-list.component.html',
    styleUrls: ['tab-list.component.scss'],
})
export class TabListComponent {
    @Input() tabs: Tab[];
    @Input() activeTab: Tab;
    @Output() onSelect: EventEmitter<Tab> = new EventEmitter<Tab>();
    @Output() onRemove: EventEmitter<Tab> = new EventEmitter<Tab>();

    @ViewChild('container', { read: DragScrollComponent, static: false })
    ds: DragScrollComponent;

    @ViewChild('contextMenu', null) contextMenu: TemplateRef<any>;
    overlayRef: OverlayRef | null;
    sub: Subscription;
    contextMenuOpen: boolean = false;

    constructor(private router: Router, public overlay: Overlay, public viewContainerRef: ViewContainerRef) {}

    onSelectClick(tab: Tab) {
        this.onSelect.emit(tab);
    }

    onRemoveClick(tab: Tab) {
        if (this.activeTab) {
            if (tab.url === this.activeTab.url) {
                const index = this.tabs.map(t => t.url).indexOf(tab.url);
                if (index > 0) {
                    this.router.navigateByUrl(this.tabs[index - 1].url);
                } else if (index === 0 && this.tabs.length > 1) {
                    this.router.navigateByUrl(this.tabs[index + 1].url);
                } else if (index === 0 && this.tabs.length === 1) {
                    this.router.navigateByUrl('/dashboard');
                }
            }
        } else {
            this.router.navigateByUrl('/dashboard');
        }
        this.onRemove.emit(tab);
        if (this.contextMenuOpen) {
            this.closeContextMenu();
        }
    }

    onRemoveAllOtherClick(tab: Tab) {
        this.activeTab = tab;
        const index = this.tabs.map(t => t.url).indexOf(tab.url);
        this.router.navigateByUrl(this.tabs[index].url);
        // tslint:disable-next-line: prefer-const
        for (let tabArray of this.tabs) {
            if (tabArray !== tab) {
                this.onRemoveClick(tabArray);
            }
        }
    }

    onRemoveAll() {
        this.router.navigateByUrl('/dashboard');
        if (this.contextMenuOpen) {
            this.closeContextMenu();
        }
        // tslint:disable-next-line: prefer-const
        for (let tabArray of this.tabs) {
            this.onRemove.emit(tabArray);
        }
    }

    onLeftClick() {
        this.ds.moveLeft();
    }

    onRightClick() {
        this.ds.moveRight();
    }

    openContextMenu({ x, y }: MouseEvent, tab) {
        if (this.contextMenuOpen) {
            this.closeContextMenu();
        }
        this.contextMenuOpen = true;
        const positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo({ x, y })
            .withPositions([
                {
                    originX: 'end',
                    originY: 'bottom',
                    overlayX: 'end',
                    overlayY: 'top',
                },
            ]);

        this.overlayRef = this.overlay.create({
            positionStrategy,
            scrollStrategy: this.overlay.scrollStrategies.close(),
        });

        this.overlayRef.attach(
            new TemplatePortal(this.contextMenu, this.viewContainerRef, {
                $implicit: tab,
            }),
        );

        this.sub = fromEvent<MouseEvent>(document, 'click')
            .pipe(
                filter(event => {
                    const clickTarget = event.target as HTMLElement;
                    return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
                }),
                take(1),
            )
            .subscribe(() => this.closeContextMenu());
    }

    closeContextMenu() {
        // tslint:disable-next-line: no-unused-expression
        this.sub && this.sub.unsubscribe();
        this.contextMenuOpen = false;
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef = null;
        }
    }
}
