import { FormStoreModel } from '../form/form-store-model';

export const ADD_FORM = 'ADD_FORM';
export const REMOVE_FORM = 'REMOVE_FORM';
export const CHANGE_FORM_URL = 'CHANGE_FORM_URL';


export function formReducer(allForms: FormStoreModel[], action) {
    switch (action.type) {
        case ADD_FORM:
            const index = allForms.map(form => form.url).indexOf(action.payload.url);
            if (index === -1) {
                if (!action.payload.id) {
                    let highestId = 0;
                    allForms.map(tab => {
                        if (tab && tab.id > highestId) { highestId = tab.id; }
                    });
                    action.payload.id = highestId + 1;
                }
                return [...allForms, action.payload];
            } else {
                const newForms = [...allForms];
                newForms[index] = action.payload;
                return newForms;
            }
        case REMOVE_FORM:
            return allForms.filter(form => {
                return form.url !== action.payload.url;
            });
        case CHANGE_FORM_URL:
            allForms.map(form => {
                if (form.id === action.payload.id) {
                    form.url = action.payload.url ? action.payload.url : form.url;
                }
            });
            return allForms;
        default:
            return allForms;
    }
}
