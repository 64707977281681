import { createReducer, on } from '@ngrx/store';
import { BestellpositionRequest } from '@shipping/models/bestellposition-request';
import * as ShippingDialogActions from '../actions/shipping-dialog.actions';
import { BestellpositionDTO } from '@shipping/models/bestellposition-dto';
import { LieferpositionDto } from '@shipping/models/lieferposition-dto';
import { SizeQuantity } from '@shipping/models/size-quantity';
import { LieferpositionRequest } from '@shipping/models/lieferposition-request';
import { BestellpositionResponse, ContainerDTO } from '@shipping/models/bestellposition-response';

export interface ShippingdialogState {
    searchRequest: BestellpositionRequest;
    lieferpositionRequest: LieferpositionRequest;
    bestellpositionResponse: BestellpositionResponse;
    lieferpositionen: LieferpositionDto[];
    sizeQuantities: SizeQuantity[];
    url: string;
}

export const initialState: ShippingdialogState = {
    searchRequest: null,
    lieferpositionRequest: null,
    bestellpositionResponse: null,
    lieferpositionen: [],
    sizeQuantities: [],
    url: '',
};

const _shippingDialogReducer = createReducer(
    initialState,
    /**
     * searchRequest
     */
    on(ShippingDialogActions.addSearchRequest, (state, { searchRequest }) => ({
        ...state,
        searchRequest: searchRequest,
    })),
    /**
     * lieferpositionRequest
     */
    on(ShippingDialogActions.addLieferpositionRequest, (state, { lieferpositionRequest }) => ({
        ...state,
        lieferpositionRequest: lieferpositionRequest,
    })),
    on(ShippingDialogActions.deleteLieferpositionRequest, state => ({
        ...state,
        lieferpositionRequest: null,
    })),
    /**
     * bestellpositionResponse
     */
    on(ShippingDialogActions.addBestellpositionen, (state, { bestellpositionResponse: bestellpositionResponse }) => ({
        ...state,
        bestellpositionResponse: bestellpositionResponse,
    })),
    on(ShippingDialogActions.updateBestellposition, (state, { bestellpositionDto: bestellpositionDto }) => ({
        ...state,
        bestellpositionResponse: updateBestellposition(state, bestellpositionDto),
    })),
    on(ShippingDialogActions.updateContainerInBestellpositionResponse, (state, { containerDTO: containerDTO }) => ({
        ...state,
        bestellpositionResponse: updateContainer(state, containerDTO),
    })),
    on(ShippingDialogActions.loadContainerDTOsuccessfully, (state, { containerDTOs: containerDTO }) => ({
        ...state,
        bestellpositionResponse: updateContainer(state, containerDTO),
    })),

    /**
     * lieferpositionen
     */
    on(ShippingDialogActions.addLieferpositionen, (state, { lieferpositionen }) => ({
        ...state,
        lieferpositionen: lieferpositionen,
    })),
    on(ShippingDialogActions.deleteLieferpositionen, state => ({
        ...state,
        lieferpositionen: [],
    })),
    /**
     * sizeQuantities
     */
    on(ShippingDialogActions.addSizeQuantities, (state, { sizeQuantites: sizeQuantities }) => ({
        ...state,
        sizeQuantities: sizeQuantities,
    })),
    on(ShippingDialogActions.deleteSizeQuantities, state => ({
        ...state,
        sizeQuantities: [],
    })),
    /**
     * url
     */
    on(ShippingDialogActions.addUrl, (state, { url }) => ({
        ...state,
        url: url,
    })),
    /**
     * Sonstiges
     */
    on(ShippingDialogActions.deleteByurl, (state, { url }) => {
        if (url === state.url) {
            state = initialState;
        }
        return state;
    }),
);
export function shippingDialogReducer(state, action): ShippingdialogState {
    return _shippingDialogReducer(state, action);
}

function updateBestellposition(state: ShippingdialogState, bestellpositionDto: BestellpositionDTO) {
    const index = state.bestellpositionResponse.bestellpositionen.findIndex(
        bestellposition => bestellposition.id === bestellpositionDto.id,
    );
    if (index > -1) {
        state.bestellpositionResponse.bestellpositionen[index] = bestellpositionDto;
    }
    return state.bestellpositionResponse;
}

function updateContainer(state: ShippingdialogState, containerDTO: ContainerDTO[]): BestellpositionResponse {
    state.bestellpositionResponse.container = containerDTO;
    return state.bestellpositionResponse;
}
