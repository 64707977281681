import { HttpClient } from '@angular/common/http';
import { BaseService } from '@core/service/base.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@auth/auth.service';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../auth/auth.service";
import * as i4 from "@angular/common/http";
export class EinmalHinweisService extends BaseService {
    constructor(toastr, translate, authService, httpClient) {
        super(toastr, translate, authService);
        this.toastr = toastr;
        this.translate = translate;
        this.authService = authService;
        this.httpClient = httpClient;
        this.einmalhinweis$$ = new BehaviorSubject(null);
        this.einmalhinweis$ = this.einmalhinweis$$.asObservable();
    }
    send(einmalhinweis) {
        this.einmalhinweis$$.next(einmalhinweis);
    }
    getAllEhKeysFromUser() {
        this.httpClient
            .get(`${environment.apiBaseUrl}/einmalhinweis`, {
            withCredentials: true,
        })
            .subscribe(data => {
            this.einmalhinweis = data;
            this.send(data);
        });
    }
    saveKey(ehKey) {
        this.httpClient
            .put(`${environment.apiBaseUrl}/einmalhinweis/saveKey`, ehKey, { withCredentials: true })
            .subscribe(() => {
            const neu = {
                id: null,
                userId: null,
                einmalhinweiskey: ehKey,
                closed_on: null,
            };
            this.einmalhinweis.push(neu);
            this.send(this.einmalhinweis);
        }, err => {
            this.showErrorMsg('user.createProblem');
            return this.handleError(err);
        });
    }
}
EinmalHinweisService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EinmalHinweisService_Factory() { return new EinmalHinweisService(i0.ɵɵinject(i1.ToastrService), i0.ɵɵinject(i2.TranslateService), i0.ɵɵinject(i3.AuthService), i0.ɵɵinject(i4.HttpClient)); }, token: EinmalHinweisService, providedIn: "root" });
