import { BaseService } from '@core/service/base.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { catchError, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../auth/auth.service";
import * as i4 from "@angular/common/http";
export class PurchaseOrderPositionService extends BaseService {
    constructor(toastr, translate, authService, httpClient) {
        super(toastr, translate, authService);
        this.toastr = toastr;
        this.translate = translate;
        this.authService = authService;
        this.httpClient = httpClient;
        this.url = `${environment.apiBaseUrl}/purchaseOrderPosition`;
    }
    getBestellpositionDTO(purchaseOrderPositionRequest) {
        return this.httpClient
            .post(this.url, purchaseOrderPositionRequest, {
            withCredentials: true,
        })
            .pipe(map(data => {
            switch (data.orderPlacement) {
                case true:
                    return data;
                    break;
                case false:
                    this.showErrorMsg('generic.problems.orderPlacementNotConfirmed');
                    return null;
                    break;
                case undefined:
                    this.showErrorMsg('generic.problems.downloadAll');
                    return data;
                    break;
                default:
                    return data;
                    break;
            }
        }), catchError(err => {
            this.showErrorMsg('generic.problems.downloadAll');
            return this.handleError(err);
        }));
    }
    getContainerDTO(projectId) {
        return this.httpClient
            .post(this.url + '/containerDTO', projectId, {
            withCredentials: true,
        })
            .pipe(catchError(err => {
            this.showErrorMsg('generic.problems.downloadAll');
            return this.handleError(err);
        }));
    }
    getSingleBestellpositionDTO(bestellposititonId) {
        return this.httpClient
            .get(this.url + `/` + bestellposititonId, {
            withCredentials: true,
        })
            .pipe(catchError(err => {
            this.showErrorMsg('generic.problems.downloadAll');
            return this.handleError(err);
        }));
    }
}
PurchaseOrderPositionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PurchaseOrderPositionService_Factory() { return new PurchaseOrderPositionService(i0.ɵɵinject(i1.ToastrService), i0.ɵɵinject(i2.TranslateService), i0.ɵɵinject(i3.AuthService), i0.ɵɵinject(i4.HttpClient)); }, token: PurchaseOrderPositionService, providedIn: "root" });
