import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { NavigationExtras } from '@angular/router';
import { User } from '../user/user';
import { TodoNotification } from '../main/notification/todo-notification';

const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

@Injectable()
export class AuthService {
    private authenticated = false;
    private redirectUrl = '/dashboard';
    private user: User;
    private userPermissions = null;
    private todoNotification: TodoNotification;
    private nextDataPermission: string;

    constructor(private router: Router, private httpClient: HttpClient) {}

    public hasPermissions(permission) {
        let checkResult = false;

        if (permission === '') {
            checkResult = true;
        } else if (permission && this.getUserPermissions()) {
            for (const userPermission of this.getUserPermissions()) {
                if (permission instanceof Array) {
                    for (const singlePermission of permission) {
                        if (singlePermission === '') {
                            checkResult = true;
                            break;
                        } else if (this.checkString(singlePermission, userPermission)) {
                            checkResult = true;
                            break;
                        }
                    }
                } else if (permission.indexOf('Permissions.') !== -1) {
                    if (this.checkString(permission.substr(12, permission.length), userPermission)) {
                        checkResult = true;
                        break;
                    }
                } else {
                    if (this.checkString(permission, userPermission)) {
                        checkResult = true;
                        break;
                    }
                }
            }
        }
        return checkResult;
    }

    private checkString(a: string, b: string): boolean {
        return a === b ? true : false;
    }

    login(username: string, password: string): Promise<any> {
        const body = new HttpParams().set('username', username).set('password', password);

        return this.httpClient
            .post<any>(`${environment.apiBaseUrl}/login`, body, {
                headers: headers,
                withCredentials: true,
            })
            .toPromise()
            .then(data => {
                this.authenticated = true;
                this.user = data.user;
                this.userPermissions = data.permissions;
                this.todoNotification = data.todoNotification;
                this.router.navigateByUrl(this.redirectUrl);
                return data;
            })
            .catch(err => {
                this.authenticated = false;
                return Promise.reject(err);
            });
    }

    logout() {
        return this.httpClient
            .post<any>(`${environment.apiBaseUrl}/logout`, null, {
                headers: headers,
                withCredentials: true,
            })
            .toPromise()
            .then(data => {
                this.goToLoginPage({ queryParams: { logout: 'true' } });
                return data;
            })
            .catch(err => {
                return Promise.reject(err);
            });
    }

    expiredSession() {
        this.goToLoginPage({ queryParams: { expiredSession: 'true' } });
    }

    public isAuthenticated(): boolean {
        return this.authenticated;
    }

    private goToLoginPage(params?: NavigationExtras) {
        this.authenticated = false;
        this.router.navigate(['session/loginone'], params);
    }

    setRedirectUrl(url: string): void {
        this.redirectUrl = url;
    }

    getRedirectUrl() {
        return this.redirectUrl;
    }

    public getUser() {
        return this.user;
    }

    public getTodoNotification() {
        return this.todoNotification;
    }

    public getUserPermissions() {
        return this.userPermissions;
    }

    public setNextDataPermission(permission: string) {
        this.nextDataPermission = permission;
    }
    public getNextDataPermission() {
        return this.nextDataPermission;
    }

    checkIsUserSupplier(): boolean {
        return this.user && this.user.role && this.user.role.id && this.user.role.id === 'SUPPLIER' ? true : false;
    }
}
