<div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-half-lg-down">
    <div class="chk-block">
        <div class="chk-block-content ">
            <div>

                <div class="row">
                    <div class="col-sm-12">
                        <ul class="to-do-list-wrap list-unstyled" perfect-scrollbar>
                            <li *ngFor="let todo of todos">
                                <label class="custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0 w-100 d-flex justify-content-between">
                                    <div>
                                        <input type="checkbox" class="custom-control-input" [(ngModel)]="todo.done"
                                            (click)="changeCheckbox(todo)">
                                        <span class="custom-control-indicator"></span>
                                        <span class="custom-control-description">{{ todo.text }}</span>
                                    </div>
                                    <div>
                                        <span *ngIf="!todo.done" class="custom-control-description">
                                            {{'general.deadline' | translate}}: {{ todo.deadline | date: 'yyyy-MM-dd'}}</span>
                                        <span *ngIf="todo.done" class="custom-control-description">
                                            {{'general.finishedOn'| translate}}: {{ todo.done | date: 'yyyy-MM-dd'}}</span>

                                    </div>
                                </label>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
