import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetDirective } from './widget/widget.directive';
import { HasPermissionDirective } from './permission/has-permission.directive';
import { AutofocusDirective } from './auto-focus.directive';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        WidgetDirective,
        HasPermissionDirective,
        AutofocusDirective
    ],
    exports: [
        WidgetDirective,
        HasPermissionDirective,
        AutofocusDirective
    ]
})
export class DirectivesModule { }
