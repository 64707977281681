import { Routes } from '@angular/router';
import { ShippingPageComponent } from './pages/shipping-page/shipping-page.component';
import { AuthLoginGuard } from '@session/auth-login.guard';
import { Permissions } from '@auth/enum/permissions.enum';
import { ContainerPageComponent } from './pages/container/container-page.component';
import { PackingListPageComponent } from './pages/packing-list-page/packing-list-page.component';
import { ContainerInformationPageComponent } from './pages/container-information-page/container-information-page.component';
const PACKINGLIST = 'packinglist';
const SHIPPING_DIALOG = 'shippingDialog';
const CONTAINER = 'container';
const CONTAINERINFO = 'containerInformation';
const ɵ0 = {
    tabTitle: 'general.shipping',
    permission: Permissions.READ_SHIPPING,
}, ɵ1 = {
    tabTitle: 'menu.shipping.containerSearch',
    permission: Permissions.READ_SHIPPING,
}, ɵ2 = {
    tabTitle: 'menu.shipping.containerInformation',
    permission: Permissions.READ_SHIPPING,
}, ɵ3 = {
    tabTitle: 'packinglist.title',
    permission: Permissions.READ_SHIPPING,
};
const routes = [
    {
        path: '',
        children: [
            {
                path: SHIPPING_DIALOG,
                component: ShippingPageComponent,
                canActivate: [AuthLoginGuard],
                data: ɵ0,
            },
            {
                path: CONTAINER,
                component: ContainerPageComponent,
                canActivate: [AuthLoginGuard],
                data: ɵ1,
            },
            {
                path: CONTAINERINFO,
                component: ContainerInformationPageComponent,
                canActivate: [AuthLoginGuard],
                data: ɵ2,
            },
            {
                path: PACKINGLIST,
                component: PackingListPageComponent,
                canActivate: [AuthLoginGuard],
                data: ɵ3,
            },
        ],
    },
];
export class ShippingRoutingModule {
}
ShippingRoutingModule.PACKINGLIST_URL = PACKINGLIST;
export { ɵ0, ɵ1, ɵ2, ɵ3 };
