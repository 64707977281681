import { Action, createReducer, on } from '@ngrx/store';
import * as PackinglistActions from '../actions/packinglist.actions';
import { ShippingRoutingModule } from '@shipping/shipping-routing.module';
import { AppRoutingModule } from '@app/app-routing.module';
import { ProjectDTO, ContainerDTO } from '@shipping/models/bestellposition-response';
import { Karton } from '@shipping/models/karton';
import { KartonContent } from '@shipping/models/karton-content';
import { KartonDTO } from '@shipping/models/karton-dto';
import { KartonsAgGrid } from '@shipping/models/kartonsAgGrid';
export const packinglistFeatureKey = 'packinglist';

export interface PackinglistState {
    containerDTO: ContainerDTO;
    project: ProjectDTO;

    kartons: KartonDTO[];
    kartonsAgGrid: KartonsAgGrid[];
    detailKarton: Karton;
    kartonContent: KartonContent;
    customerShippingMode: string;
    customerDivsion: string;
}

export const initialState: PackinglistState = {
    containerDTO: null,
    project: null,
    kartons: [],
    kartonsAgGrid: [],
    detailKarton: null,
    kartonContent: null,
    customerShippingMode: null,
    customerDivsion: null,
};

const _packinglistReducer = createReducer(
    initialState,
    on(PackinglistActions.deleteByurl, (state, { url }) => {
        if (url === '/' + AppRoutingModule.SHIPPING_URL + '/' + ShippingRoutingModule.PACKINGLIST_URL) {
            state = initialState;
        }
        return state;
    }),
    on(PackinglistActions.addProject, (state, { project }) => ({
        ...state,
        project: project,
        containerDTO: null,
    })),
    on(PackinglistActions.addContainerDTO, (state, { containerDTO }) => ({
        ...state,
        containerDTO: containerDTO,
    })),
    on(PackinglistActions.setCustomerShipModeAndDivisonSuccessfilly, (state, { shipmode, division }) => ({
        ...state,
        customerShippingMode: shipmode,
        customerDivsion: division,
    })),
    on(PackinglistActions.loadKartonsSuccessfully, (state, { kartons }) => ({
        ...state,
        kartons: kartons,
        kartonsAgGrid: createKartonAgGridData(kartons),
    })),
    on(PackinglistActions.addDetailKartonSuccesfully, (state, { karton }) => ({
        ...state,
        detailKarton: karton,
        kartonContent: null,
    })),
    on(PackinglistActions.deleteDetailKarton, state => ({
        ...state,
        detailKarton: null,
    })),
    on(PackinglistActions.loadEmptyKartonContentSuccessfully, (state, { kartonContent }) => ({
        ...state,
        kartonContent: kartonContent,
    })),
    on(PackinglistActions.loadExistingKartonContentSuccessfully, (state, { kartonContent }) => ({
        ...state,
        kartonContent: kartonContent,
    })),
    on(PackinglistActions.updateKartonContent, (state, { kartonContent }) => ({
        ...state,
        kartonContent: kartonContent,
    })),
    on(PackinglistActions.deleteKartonContent, state => ({
        ...state,
        kartonContent: null,
    })),
    on(PackinglistActions.selectAllKartonsForPrintSuccesfully, (state, { kartons }) => ({
        ...state,
        kartons: kartons,
        kartonsAgGrid: createKartonAgGridData(kartons),
    })),
);

export function packinglistReducer(state: PackinglistState | undefined, action: Action) {
    return _packinglistReducer(state, action);
}

export function createKartonAgGridData(kartons: KartonDTO[]) {
    const kartonsForAgGrid: KartonsAgGrid[] = [];
    let lastKarton: KartonsAgGrid = null;
    let pathIndex = 0;
    let pathSubindex = 0;

    kartons.forEach(karton => {
        if (
            lastKarton !== null &&
            lastKarton.abmessung === karton.abmessung &&
            lastKarton.kartonGroessen === karton.kartonGroessen &&
            lastKarton.gewicht === karton.gewicht &&
            lastKarton.kartonContentAmount === karton.kartonContentAmount
        ) {
            lastKarton = {
                abmessung: karton.abmessung,
                cbox: karton.cbox,
                createdAt: karton.createdAt,
                createdBy: karton.createdBy,
                nummer: karton.nummer,
                gewicht: karton.gewicht,
                id: karton.id,
                kartonContentAmount: karton.kartonContentAmount,
                kartonGroessen: karton.kartonGroessen,
                nve: karton.nve,
                path: [pathIndex.toString(), karton.nummer.toString()],
            };
            pathSubindex++;
        } else {
            pathIndex = karton.nummer;
            lastKarton = {
                abmessung: karton.abmessung,
                cbox: karton.cbox,
                createdAt: karton.createdAt,
                createdBy: karton.createdBy,
                nummer: karton.nummer,
                gewicht: karton.gewicht,
                id: karton.id,
                kartonContentAmount: karton.kartonContentAmount,
                kartonGroessen: karton.kartonGroessen,
                nve: karton.nve,
                path: [pathIndex.toString()],
            };
        }
        kartonsForAgGrid.push(lastKarton);
    });

    return kartonsForAgGrid;
}
