import { Router } from '@angular/router';

/**
 * @author Fabian Lies
 * @description weil "don't repeat yourself"
 * @param router @Angular/router
 * @param number Container Number
 */
export function openContainerSerachDialog(router: Router, number: string) {
    router.navigate(['/shipping/container'], {
        queryParams: {
            containerNumber: number,
        },
        queryParamsHandling: 'preserve',
    });
}

// TODO: export function openVersandDialog(router: Router) {}
