import {Tab} from '../tab/tab';

export const CHANGE_ACTIVE_TAB = 'CHANGE_ACTIVE_TAB';

export function activeTabReducer(activeTab: Tab, action) {
  switch (action.type) {
    case CHANGE_ACTIVE_TAB:
      return action.payload;
    default:
      return activeTab;
  }
}
