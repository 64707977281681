import {Component, OnInit} from '@angular/core';
import {Tab} from './tab';
import {AppState} from '../app-state';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {TabStoreService} from './tab-store.service';
import {Router} from '@angular/router';
import {FormService} from '../form/form.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tab.component.html',
  styleUrls: []
})
export class TabComponent implements OnInit {

  public tabs: Observable<Tab[]>;
  public activeTab: Observable<Tab>;

  constructor(private store: Store<AppState>,
              private tabsStoreService: TabStoreService,
              private router: Router) {
  }

  ngOnInit() {
    this.tabs = this.store.select(state => state.tabs);
    this.activeTab = this.store.select(state => state.activeTab);
  }

  onSelect(tab: Tab) {
    this.tabsStoreService.changeActiveTab(tab);
    this.router.navigateByUrl(tab.url);
  }

  onRemove(tab: Tab) {
    this.tabsStoreService.removeTabByUrl(tab.url);
  }

}
