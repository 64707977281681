import { Component, OnInit, OnDestroy, Input, AfterContentInit, AfterViewInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { TodoNotification } from './todo-notification';
import { Subscription } from 'rxjs';
import { TodoService } from '../../todos/todo.service';
import { ConfigurationService } from '../../administration/configuration/configuration.service';
import { ConfigurationParameter } from '../../administration/configuration/ConfigurationParameter';
import { Todo } from '../../todos/todo';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {
    public todoNotification: TodoNotification;
    private subscriptionToDoNoti: Subscription;
    private subscriptionConfigParams: Subscription;
    public todoCount = 0;
    public todoNotificationSize = 0;
    public params: ConfigurationParameter[];

    constructor(private authService: AuthService,
        private todoService: TodoService,
        private configurationService: ConfigurationService
    ) {
        this.subscriptionToDoNoti = todoService.getTodoNotification().pipe().subscribe(
            data => {
                const todos: Todo[] = data;
                this.todoNotification.todos = todos.filter(todo => !todo.done);
                // this.todoNotification.todos = todoNotification;
                this.todoCount = this.todoNotification.todos.length;
            }
        );
        this.subscriptionConfigParams = this.todoService.getCPtodoConfigurationSize()
            .subscribe(cnt => {
                this.todoNotificationSize = cnt ? cnt.value : 0;
                if (this.todoNotification &&
                    this.todoNotification.todos &&
                    this.todoNotificationSize > this.todoNotification.todos.length) {
                    this.todoService.getTodosByUserId(this.authService.getUser().id)
                        .then(data => {
                            const todos: Todo[] = data;
                            this.todoNotification.todos = todos.filter(todo => !todo.done);
                        });
                }
            });
    }


    ngOnInit() {
        if (this.authService.getUser()) {
            this.todoNotification = this.authService.getTodoNotification();
            this.todoCount = this.todoNotification.total;
        }
    }


    ngOnDestroy() {
        this.subscriptionToDoNoti.unsubscribe();
        this.subscriptionConfigParams.unsubscribe();
    }


}


