import { AgRendererComponent, ICellRendererAngularComp } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { StepManagementRowDTODetails } from '@project/models/step-management-row-dto-details';
import { openContainerSerachDialog } from '@shipping/utils/link-to-containersearch';

@Component({
    selector: 'app-container-link-cell-component',
    template: `
        <span>
            <ng-container *ngFor="let detail of details; let last = last">
                <a href="javascript:void(0)" (click)="openContainerSearchDialogFunk(detail.containerNumber)">
                    {{ detail.containerNumber }}
                </a>
                <span *ngIf="!last">, </span>
            </ng-container>
        </span>
    `,
})
export class ContainerLinkCellComponentComponent implements AgRendererComponent {
    details: StepManagementRowDTODetails[];

    constructor(private router: Router) {}

    agInit(params: any): void {
        this.details = params.data.stepManagementRowDTODetails;
    }

    refresh(params: any): boolean {
        this.details = params.value;
        return true;
    }

    openContainerSearchDialogFunk(containerNumber: string): void {
        // Hier können Sie Ihre Logik für das Öffnen des Dialogs implementieren
        openContainerSerachDialog(this.router, containerNumber);
    }
}
