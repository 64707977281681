import { createAction, props } from '@ngrx/store';
import { Container } from '@shipping/models/container';
import { ContainerSearchRequest } from '@shipping/models/container-search-request';

export const loadContainers = createAction('[Container] Load Containers');
export const addContainers = createAction(
    '[Container] AddContainers',
    props<{ containers: Container[] }>()
);
export const addSearchRequest = createAction(
    '[Container] AddSearchRequest',
    props<{ searchRequest: ContainerSearchRequest }>()
);
export const addUrl = createAction(
    '[Container] AddUrl',
    props<{ url: string }>()
);
export const deleteByurl = createAction(
    '[Container] Delete Container',
    props<{ url: string }>()
);
export const deleteContainerList = createAction(
    '[Container] DeleteContainerList'
);
