import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app-state';
import { FormStoreModel } from './form-store-model';
import { ADD_FORM, REMOVE_FORM, CHANGE_FORM_URL } from '../reducers/form.reducer';
import { FormGroup } from '@angular/forms';

@Injectable()
export class FormService {

    constructor(private store: Store<AppState>) {

    }

    addFormByUrl(url: string, form: FormGroup) {
        this.addForm({
            id: null,
            url: url,
            formGroupValue: form.value
        });
    }

    removeFormByUrl(url: string) {
        this.removeForm({
            id: null,
            url: url,
            formGroupValue: {}
        });
    }

    changeUrlById(id: number, url: string) {
        this.changeUrl({
            id: id,
            url: url,
            formGroupValue: {}
        });
    }

    addForm(form: FormStoreModel) {
        this.store.dispatch({
            type: ADD_FORM,
            payload: form
        });
    }

    removeForm(form: FormStoreModel) {
        this.store.dispatch({
            type: REMOVE_FORM,
            payload: form
        });
    }

    changeUrl(form: FormStoreModel) {
        this.store.dispatch({
            type: CHANGE_FORM_URL,
            payload: form
        });
    }

    findIndexOfUrl(forms: FormStoreModel[], url: string): number {
        return forms.filter(form => !!form).map(form => form.url).indexOf(url);
    }
}
