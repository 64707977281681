import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '@core/page-title/page-title.service';
import { Masterdata } from '@main/masterdata';
import { MasterdataService } from '@main/masterdata.service';
import { take, map } from 'rxjs/operators';
import { ContainerSearchRequest } from '@shipping/models/container-search-request';
import { ContainerInformationService } from '@shipping/services/container-information.service';
import { ContainerInformation } from '@shipping/models/container-information';
import { Observable } from 'rxjs';
import { ShipperService } from '@shipping/services/shipper.service';
import { FreightpaymentService } from '@shipping/services/freightpayment.service';
import { Shipper } from '@shipping/models/shipper';
import { FreightPayment, emptyFreightPayment } from '@shipping/models/freightpayment';

@Component({
    templateUrl: './container-information-page.component.html',
    styleUrls: ['./container-information-page.component.scss'],
})
export class ContainerInformationPageComponent implements OnInit {
    projectTypes: string[];
    masterdata: Masterdata;

    shipper$: Observable<Shipper[]>;
    freightpayment$: Observable<FreightPayment[]>;

    containerInformations: Observable<ContainerInformation[]>;

    constructor(
        private pageTitleService: PageTitleService,
        private masterdataService: MasterdataService,
        private containerInformationService: ContainerInformationService,
        protected shipperService: ShipperService,
        protected freightpaymentService: FreightpaymentService,
    ) {
        this.shipper$ = shipperService.getAll();
        this.freightpayment$ = freightpaymentService.getAll().pipe(
            map(data => {
                return [...[emptyFreightPayment], ...data];
            }),
        );
    }

    ngOnInit() {
        this.pageTitleService.setTitle('menu.shipping.containerInformation');
        this.masterdataService
            .loadMasterdata()
            .pipe(take(1))
            .subscribe(data => {
                this.masterdata = data;
                this.projectTypes = data.projectTypes;
            });
    }

    onSearch(containerSearchRequest: ContainerSearchRequest): void {
        this.containerInformations = this.containerInformationService.search(containerSearchRequest);
    }
}
