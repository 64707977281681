import { Routes, RouterModule } from '@angular/router';

import { MainComponent } from './main/main.component';
import { AuthLoginGuard } from './session/auth-login.guard';
import { TodosComponent } from './todos/todos.component';
import { Permissions } from './auth/enum/permissions.enum';
import { NgModule } from '@angular/core';

const SHIPPING = 'shipping';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: '',
        component: MainComponent,
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
            },
            {
                path: 'masterdata/company',
                loadChildren: () => import('./company/company.module').then(m => m.CompanyModule),
            },
            {
                path: 'project',
                loadChildren: () => import('./project/project.module').then(m => m.ProjectModule),
            },
            {
                path: 'workflow',
                loadChildren: () => import('./workflow/workflow.module').then(m => m.WorkflowModule),
            },
            {
                path: 'reporting',
                loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportingModule),
            },
            {
                path: 'user',
                loadChildren: () => import('./user/user.module').then(m => m.UserModule),
            },
            {
                path: 'drawPanel',
                loadChildren: () => import('./draw/draw-panel.module').then(m => m.DrawPanelModule),
            },
            {
                path: 'administration',
                loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule),
            },
            {
                path: 'todos',
                component: TodosComponent,
                data: {
                    tabTitle: 'general.todos',
                    permission: Permissions.READ_TODO,
                },
                canActivate: [AuthLoginGuard],
            },
            {
                path: 'nos',
                loadChildren: () => import('./nos/nos.module').then(m => m.NosModule),
            },
            {
                path: SHIPPING,
                loadChildren: () => import('./shipping/shipping.module').then(m => m.ShippingModule),
            },
            {
                path: 'calculation',
                loadChildren: () => import('./calculation/calculation.module').then(m => m.CalculationModule),
            },
            {
                path: 'salesman',
                loadChildren: () => import('./salesman/salesman.module').then(m => m.SalesmanModule),
            },
        ],
    },
    {
        path: '',
        children: [
            {
                path: 'session',
                loadChildren: () => import('./session/session.module').then(m => m.SessionModule),
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
})
export class AppRoutingModule {
    static SHIPPING_URL = SHIPPING;
}
