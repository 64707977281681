import { ɵEMPTY_ARRAY } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-toastr";
import * as i3 from "@ngx-translate/core";
export class PackingListService {
    constructor(httpClient, toastr, translate) {
        this.httpClient = httpClient;
        this.toastr = toastr;
        this.translate = translate;
    }
    /**
     * GET all container
     */
    getKartons(projectId, containerId, kundenauftragsnummer) {
        const params = new HttpParams().append('project', '' + projectId).append('container', '' + containerId);
        if (kundenauftragsnummer) {
            params.append('customerodernumber', kundenauftragsnummer);
        }
        return this.httpClient
            .get(`${environment.apiBaseUrl}/karton`, {
            withCredentials: true,
            params: params,
        })
            .pipe(catchError(err => {
            const msg = this.translate.instant('generic.problems.downloadAll');
            const title = this.translate.instant('notifications.error');
            this.toastr.error(msg, title);
            return of(ɵEMPTY_ARRAY);
        }));
    }
    /**
     * GET empty Content
     */
    getEmptyContent(projectId, containerId, kunu) {
        const params = new HttpParams()
            .append('project', '' + projectId)
            .append('container', '' + containerId)
            .append('kunu', kunu);
        return this.httpClient
            .get(`${environment.apiBaseUrl}/karton/content`, {
            withCredentials: true,
            params: params,
        })
            .pipe(catchError(err => {
            const msg = this.translate.instant('generic.problems.downloadAll');
            const title = this.translate.instant('notifications.error');
            this.toastr.error(msg, title);
            return of(ɵEMPTY_ARRAY);
        }));
    }
    /**
     * GET existing Content
     */
    getExistingContent(kartonId) {
        const params = new HttpParams().append('karton', '' + kartonId);
        return this.httpClient
            .get(`${environment.apiBaseUrl}/karton/content`, {
            withCredentials: true,
            params: params,
        })
            .pipe(catchError(err => {
            const msg = this.translate.instant('generic.problems.downloadAll');
            const title = this.translate.instant('notifications.error');
            this.toastr.error(msg, title);
            return of(ɵEMPTY_ARRAY);
        }));
    }
    /**
     * POST update container
     */
    update(request) {
        return this.httpClient
            .post(`${environment.apiBaseUrl}/karton`, request, {
            withCredentials: true,
        })
            .pipe(catchError(err => {
            const msg = this.translate.instant('generic.problems.downloadAll');
            const title = this.translate.instant('notifications.error');
            this.toastr.error(msg, title);
            return of(ɵEMPTY_ARRAY);
        }));
    }
    /*
    DELETE
    */
    delete(id) {
        return this.httpClient
            .delete(`${environment.apiBaseUrl}/karton/` + id, {
            withCredentials: true,
        })
            .pipe(catchError(err => {
            const msg = this.translate.instant('generic.problems.remove');
            const title = this.translate.instant('notifications.error');
            this.toastr.error(msg, title);
            return of(ɵEMPTY_ARRAY);
        }));
    }
    /**
     * Delete By a List Of Ids
     */
    deleteWithListOfIds(listOfKartonIds) {
        return this.httpClient
            .request('delete', `${environment.apiBaseUrl}/karton/deleteByIds`, {
            body: listOfKartonIds,
            withCredentials: true,
        })
            .pipe(catchError(err => {
            const msg = this.translate.instant('generic.problems.remove');
            const title = this.translate.instant('notifications.error');
            this.toastr.error(msg, title);
            return of(ɵEMPTY_ARRAY);
        }));
    }
    /**
     * get Customer Shipmode and Division
     */
    getCustomerShipModeAndDivision(projectId, containerId, kundenauftragsnummer) {
        const params = new HttpParams()
            .append('project', projectId.toString())
            .append('container', containerId.toString())
            .append('kundenauftragsnummer', kundenauftragsnummer);
        return this.httpClient
            .get(`${environment.apiBaseUrl}/karton/costomerShipModeDivision`, {
            withCredentials: true,
            params: params,
        })
            .pipe(catchError(err => {
            const msg = this.translate.instant('generic.problems.downloadAll');
            const title = this.translate.instant('notifications.error');
            this.toastr.error(msg, title);
            return of(ɵEMPTY_ARRAY);
        }));
    }
    setCustomerShipModeAndDivision(projectId, containerId, kundenauftragsnummer, shipmode, division) {
        const params = new HttpParams()
            .append('project', projectId.toString())
            .append('container', containerId.toString())
            .append('kundenauftragsnummer', kundenauftragsnummer)
            .append('shipmode', shipmode)
            .append('division', division);
        return this.httpClient
            .post(`${environment.apiBaseUrl}/karton/costomerShipModeDivision/shipmodeanddivision`, null, {
            withCredentials: true,
            params: params,
        })
            .pipe(catchError(err => {
            const msg = this.translate.instant('generic.problems.downloadAll');
            const title = this.translate.instant('notifications.error');
            this.toastr.error(msg, title);
            return of(ɵEMPTY_ARRAY);
        }));
    }
}
PackingListService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PackingListService_Factory() { return new PackingListService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ToastrService), i0.ɵɵinject(i3.TranslateService)); }, token: PackingListService, providedIn: "root" });
