import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { LicenseManager } from '@ag-grid-enterprise/core';
LicenseManager.setLicenseKey(
    'todo42_Software_GmbH_Alexander_Sturm_multi_1_Devs_1_Deployment_License_16_December_2020_[v2]_MTYwODA3NjgwMDAwMA==8e287864c8016f748614b0f8c42a9cc4',
);

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
