import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UserSettingTypes } from './user-setting-types.enum';
import { UserSettings } from './user-settings';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorMessageService } from '@core/service/error-message.service';
import { SuccessMessageService } from '@core/service/success-message.service';

@Injectable()
export class UserSettingsService {
    constructor(
        private httpClient: HttpClient,
        protected toastr: ToastrService,
        protected translate: TranslateService,
        protected errorMessageService: ErrorMessageService,
        protected successMessageService: SuccessMessageService,
        protected authService: AuthService,
    ) {}

    getUserSettingsPromise(type: UserSettingTypes): Promise<any> {
        const params = new HttpParams().append('type', type.toString());
        return this.httpClient
            .get<any>(`${environment.apiBaseUrl}/userSettings`, { withCredentials: true, params: params })
            .toPromise()
            .then(data => {
                if (data) {
                    return data;
                } else {
                    return undefined;
                }
            })
            .catch(err => {
                this.errorMessageService.showErrorMsg('userSettings.problems.download');
                return this.errorMessageService.handleError(err);
            });
    }

    getUserSettings(type: UserSettingTypes): Observable<UserSettings> {
        const params = new HttpParams().append('type', type.toString());
        return this.httpClient
            .get<UserSettings>(`${environment.apiBaseUrl}/userSettings`, { withCredentials: true, params: params })
            .pipe(
                catchError(err => {
                    this.errorMessageService.showErrorMsg('userSettings.problems.download');
                    return this.errorMessageService.handleError(err);
                }),
            );
    }

    getUserSettingsV2(type: UserSettingTypes): Observable<UserSettings> {
        const params = new HttpParams().append('type', type.toString());
        return this.httpClient
            .get<UserSettings>(`${environment.apiBaseUrl}/userSettings`, { withCredentials: true, params: params })
            .pipe(
                catchError(err => {
                    this.errorMessageService.showErrorMsg('userSettings.problems.download');
                    return this.errorMessageService.handleError(err);
                }),
            );
    }

    createUserSettings(type: UserSettingTypes, payload: string, limit: number): Promise<any[]> {
        if (type && payload) {
            const userSettings = {
                type: type.toString(),
                payload: payload,
                limit: limit,
            };
            return this.httpClient
                .post<any>(`${environment.apiBaseUrl}/userSettings`, userSettings, { withCredentials: true })
                .toPromise()
                .then(data => {
                    // Rausgenommen weil auf ag Grid tabellen leicht nervig
                    // this.successMessageService.showSuccessMsg('userSettings.success.update');
                    return data;
                })
                .catch(err => {
                    this.errorMessageService.showErrorMsg('userSettings.problems.update');
                    return this.errorMessageService.handleError(err);
                });
        }
    }

    createUserSettingsV2(userSettings: UserSettings): Observable<UserSettings> {
        return this.httpClient
            .post<any>(`${environment.apiBaseUrl}/userSettings`, userSettings, { withCredentials: true })
            .pipe(
                catchError(err => {
                    this.errorMessageService.showErrorMsg('userSettings.problems.update');
                    return this.errorMessageService.handleError(err);
                }),
            );
    }
}
