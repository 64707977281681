import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessageService {
  constructor(
    protected toastr: ToastrService,
    protected translate: TranslateService,
    protected authService: AuthService,
  ) {}

  handleError(error: any): Promise<any> {
    if (error.status === 401) {
      // session is expired
      this.toastr.clear();
      this.authService.expiredSession();
    }
    return Promise.reject(error);
  }

  showErrorMsg(key: string): void {
    const title: string = this.translate.instant('notifications.error');
    const msg: string = this.translate.instant(key);
    this.toastr.error(msg, title);
  }

  showWarningMsg(key: string): void {
    const title: string = this.translate.instant('notifications.warn');
    const msg: string = this.translate.instant(key);
    this.toastr.warning(msg, title);
  }
}
