import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../auth/auth.service';

export abstract class BaseService {
    // Service stotitle the instance in the static variable, to allow the handleError call through method reference.
    // When a call to 'this' is made, it is unavailable in such context.
    static instance;

    constructor(
        protected toastr: ToastrService,
        protected translate: TranslateService,
        protected authService: AuthService
    ) {
        BaseService.instance = this;
    }

    handleError(error: any): Promise<any> {
        if (error.status === 401) {
            // session is expired
            BaseService.instance.toastr.clear();
            this.authService.expiredSession();
        }
        return Promise.reject(error);
    }

    showErrorMsg(key: string): void {
        const title: string = this.translate.instant('notifications.error');
        const msg: string = this.translate.instant(key);
        BaseService.instance.toastr.error(msg, title);
    }

    showSuccessMsg(key: string): void {
        const title: string = this.translate.instant('notifications.success');
        const msg: string = this.translate.instant(key);
        BaseService.instance.toastr.success(msg, title);
    }

    showWarningMsg(key: string): void {
        const title: string = this.translate.instant('notifications.warn');
        const msg: string = this.translate.instant(key);
        BaseService.instance.toastr.warning(msg, title);
    }

    showInfoMsg(key: string): void {
        const title: string = this.translate.instant('notifications.info');
        const msg: string = this.translate.instant(key);
        BaseService.instance.toastr.warning(msg, title);
    }
}
