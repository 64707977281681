/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./ContainerLinkCellComponent.component";
import * as i3 from "@angular/router";
var styles_ContainerLinkCellComponentComponent = [];
var RenderType_ContainerLinkCellComponentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ContainerLinkCellComponentComponent, data: {} });
export { RenderType_ContainerLinkCellComponentComponent as RenderType_ContainerLinkCellComponentComponent };
function View_ContainerLinkCellComponentComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [", "]))], null, null); }
function View_ContainerLinkCellComponentComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openContainerSearchDialogFunk(_v.context.$implicit.containerNumber) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ContainerLinkCellComponentComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_1 = !_v.context.last; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.containerNumber; _ck(_v, 2, 0, currVal_0); }); }
export function View_ContainerLinkCellComponentComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ContainerLinkCellComponentComponent_1)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.details; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ContainerLinkCellComponentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-container-link-cell-component", [], null, null, null, View_ContainerLinkCellComponentComponent_0, RenderType_ContainerLinkCellComponentComponent)), i0.ɵdid(1, 49152, null, 0, i2.ContainerLinkCellComponentComponent, [i3.Router], null, null)], null, null); }
var ContainerLinkCellComponentComponentNgFactory = i0.ɵccf("app-container-link-cell-component", i2.ContainerLinkCellComponentComponent, View_ContainerLinkCellComponentComponent_Host_0, {}, {}, []);
export { ContainerLinkCellComponentComponentNgFactory as ContainerLinkCellComponentComponentNgFactory };
