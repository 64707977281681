export enum Permissions {
    NO_PERMISSION = '',

    CREATE_PROJECT = 'CREATE_PROJECT',
    READ_PROJECT = 'READ_PROJECT',
    UPDATE_PROJECT = 'UPDATE_PROJECT',
    DELETE_PROJECT = 'DELETE_PROJECT',

    CREATE_MASTERDATA = 'CREATE_MASTERDATA',
    READ_MASTERDATA = 'READ_MASTERDATA',
    UPDATE_MASTERDATA = 'UPDATE_MASTERDATA',
    DELETE_MASTERDATA = 'DELETE_MASTERDATA',

    CREATE_WORKFLOW = 'CREATE_WORKFLOW',
    READ_WORKFLOW = 'READ_WORKFLOW',
    UPDATE_WORKFLOW = 'UPDATE_WORKFLOW',
    DELETE_WORKFLOW = 'DELETE_WORKFLOW',

    CREATE_ORDER = 'CREATE_ORDER',
    READ_ORDER = 'READ_ORDER',
    UPDATE_ORDER = 'UPDATE_ORDER',
    DELETE_ORDER = 'DELETE_ORDER',

    CREATE_USER = 'CREATE_USER',
    READ_USER = 'READ_USER',
    UPDATE_USER = 'UPDATE_USER',
    DELETE_USER = 'DELETE_USER',

    CREATE_USERGROUP = 'CREATE_USERGROUP',
    READ_USERGROUP = 'READ_USERGROUP',
    UPDATE_USERGROUP = 'UPDATE_USERGROUP',
    DELETE_USERGROUP = 'DELETE_USERGROUP',

    CREATE_CONFIGURATION = 'CREATE_CONFIGURATION',
    READ_CONFIGURATION = 'READ_CONFIGURATION',
    UPDATE_CONFIGURATION = 'UPDATE_CONFIGURATION',
    DELETE_CONFIGURATION = 'DELETE_CONFIGURATION',

    CREATE_ROLE_PERMISSION = 'CREATE_ROLE_PERMISSION',
    READ_ROLE_PERMISSION = 'READ_ROLE_PERMISSION',
    UPDATE_ROLE_PERMISSION = 'UPDATE_ROLE_PERMISSION',
    DELETE_ROLE_PERMISSION = 'DELETE_ROLE_PERMISSION',

    CREATE_SKETCH = 'CREATE_SKETCH',
    READ_SKETCH = 'READ_SKETCH',
    UPDATE_SKETCH = 'UPDATE_SKETCH',
    DELETE_SKETCH = 'DELETE_SKETCH',

    CREATE_TODO = 'CREATE_TODO',
    READ_TODO = 'READ_TODO',
    UPDATE_TODO = 'UPDATE_TODO',
    DELETE_TODO = 'DELETE_TODO',

    READ_NOS = 'READ_NOS',

    READ_SHIPPING = 'READ_SHIPPING',
    WRITE_SHIPPING = 'WRITE_SHIPPING',

    VIEW_ALL_STEPS = 'VIEW_ALL_STEPS',

    UPDATE_PROJECTSTEP_TOGGLE_VISIBLE = 'UPDATE_PROJECTSTEP_TOGGLE_VISIBLE',
    UPDATE_PROJECTSTEP_CONFIRM = 'UPDATE_PROJECTSTEP_CONFIRM',
    UPDATE_PROJECTSTEP_FACTORIES = 'UPDATE_PROJECTSTEP_FACTORIES',

    READ_REPORTING = 'READ_REPORTING',
    READ_REPORTING_SALES = 'READ_REPORTING_SALES',

    DELETE_DOCUMENT = 'DELETE_DOCUMENT',

    READ_CONTAINER_ARRIVALS = 'READ_CONTAINER_ARRIVALS',

    READ_CALCUATION = 'READ_CALCULATION',

    READ_SALESMAN = 'READ_SALESMAN',

    READ_SHOP = 'READ_SHOP',
}
