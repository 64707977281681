import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '@core/service/base.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@auth/auth.service';
import { environment } from '../../../environments/environment';
import { Einmalhinweis } from '@einmalhinweis/models/einmal-hinweis';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EinmalHinweisService extends BaseService {
  private einmalhinweis$$: BehaviorSubject<Einmalhinweis[]> = new BehaviorSubject<Einmalhinweis[]>(null);
  einmalhinweis$: Observable<Einmalhinweis[]> = this.einmalhinweis$$.asObservable();

  private send(einmalhinweis: Einmalhinweis[]): void {
    this.einmalhinweis$$.next(einmalhinweis);
  }

  public einmalhinweis: Einmalhinweis[];

  constructor(
    protected toastr: ToastrService,
    protected translate: TranslateService,
    protected authService: AuthService,
    private httpClient: HttpClient,
  ) {
    super(toastr, translate, authService);
  }

  getAllEhKeysFromUser(): void {
    this.httpClient
      .get<Einmalhinweis[]>(`${environment.apiBaseUrl}/einmalhinweis`, {
        withCredentials: true,
      })
      .subscribe(data => {
        this.einmalhinweis = data;
        this.send(data);
      });
  }

  saveKey(ehKey: string): void {
    this.httpClient
      .put<any>(`${environment.apiBaseUrl}/einmalhinweis/saveKey`, ehKey, { withCredentials: true })
      .subscribe(
        () => {
          const neu: Einmalhinweis = {
            id: null,
            userId: null,
            einmalhinweiskey: ehKey,
            closed_on: null,
          };
          this.einmalhinweis.push(neu);
          this.send(this.einmalhinweis);
        },
        err => {
          this.showErrorMsg('user.createProblem');
          return this.handleError(err);
        },
      );
  }
}
