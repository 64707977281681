import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';
import { NgbModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SidebarModule } from 'ng-sidebar';
import 'hammerjs';
import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { REDUCERS, INITIAL_STATE, REDUCER_KEYS } from './shared/store/app-state';
import { ToastrModule } from 'ngx-toastr';
import { MenuToggleModule } from './core/menu/menu-toggle.module';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { DragScrollModule } from 'ngx-drag-scroll';

import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { TabComponent } from './shared/store/tab/tab.component';
import { TabListComponent } from './shared/store/tab/tab-list/tab-list.component';
import { TabStoreService } from './shared/store/tab/tab-store.service';
import { AuthService } from './auth/auth.service';
import { MenuItems } from './core/menu/menu-items/menu-items';
import { PageTitleService } from './core/page-title/page-title.service';
import { ScrollbarService } from './core/scrollbar/scrollbar.service';
import { AuthLoginGuard } from './session/auth-login.guard';
import { FormService } from './shared/store/form/form.service';
import { MainDataTableModule } from './core/data-table/main-data-table.module';
import { CommonModule, DatePipe, CurrencyPipe } from '@angular/common';
import { MasterdataService } from './main/masterdata.service';
import { NotificationComponent } from './main/notification/notification.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TodosComponent } from './todos/todos.component';
import { TodoService } from './todos/todo.service';
import { ConfigurationService } from './administration/configuration/configuration.service';
import { TableStoreService } from './shared/store/table/table-store.service';
import { Interceptor } from './auth/interceptor';
import { DirectivesModule } from './core/directive/directives.module';
import { AppRoutingModule } from './app-routing.module';
import { environment } from 'environments/environment';
import { FakeBackendInterceptor } from '@auth/enum/fake-backend-interceptor';
import { EffectsModule } from '@ngrx/effects';
import { PackinglistEffects } from '@shared/store/effects/packinglist.effects';
import { AgGridCheckboxComponent } from './core/ag-grid/renderer/ag-grid-checkbox/ag-grid-checkbox.component';
import { HeaderComponent } from './core/ag-grid/components/header/header.component';
import { ShippingDialogEffects } from '@shared/store/effects/shipping-dialog.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MatCheckboxRendererComponent } from '@project/shared/renderer/mat-checkbox-renderer/mat-checkbox-renderer.component';
import { MatCheckboxModule } from '@angular/material';
import { AgGridMatCheckboxComponent } from '@core/ag-grid/renderer/ag-grid-mat-checkbox/ag-grid-mat-checkbox.component';
import { ContainerLinkCellComponentComponent } from '@project/shared/renderer/ContainerLinkCellComponent/ContainerLinkCellComponent.component';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({ keys: REDUCER_KEYS, rehydrate: true })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        CommonModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        SidebarModule.forRoot(),
        FlexLayoutModule,
        NgbModalModule,
        MenuToggleModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        NgSelectModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            closeButton: true,
            preventDuplicates: true,
            resetTimeoutOnDuplicate: true,
        }),
        StoreModule.forRoot(REDUCERS, {
            initialState: INITIAL_STATE,
            metaReducers: metaReducers,
        }),
        MatCheckboxModule,
        MainDataTableModule,
        DragScrollModule,
        NgbModule,
        LoadingBarHttpClientModule,
        PerfectScrollbarModule,
        DirectivesModule,
        MatCheckboxModule,
        EffectsModule.forRoot([PackinglistEffects, ShippingDialogEffects]),
        // Instrumentation must be imported after importing StoreModule (config is optional)
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
        }),
    ],
    entryComponents: [
        AgGridCheckboxComponent,
        HeaderComponent,
        MatCheckboxRendererComponent,
        ContainerLinkCellComponentComponent,
    ],
    declarations: [
        AppComponent,
        MainComponent,
        TabComponent,
        TabListComponent,
        NotificationComponent,
        TodosComponent,
        AgGridMatCheckboxComponent,
        AgGridCheckboxComponent,
        HeaderComponent,
        MatCheckboxRendererComponent,
        ContainerLinkCellComponentComponent,
    ],
    bootstrap: [AppComponent],
    providers: [
        AuthService,
        AuthLoginGuard,
        ConfigurationService,
        DatePipe,
        CurrencyPipe,
        FormService,
        MasterdataService,
        MenuItems,
        PageTitleService,
        ScrollbarService,
        TabStoreService,
        TodoService,
        TableStoreService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Interceptor,
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useValue: 'de',
        },
        { provide: DatePipe, useClass: DatePipe, deps: [LOCALE_ID] },
        !environment.production
            ? {
                  provide: HTTP_INTERCEPTORS,
                  useClass: FakeBackendInterceptor,
                  multi: true,
              }
            : [],
    ],
})
export class AppModule {}
