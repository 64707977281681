import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { PageTitleService } from '@core/page-title/page-title.service';
import { MasterdataService } from '@main/masterdata.service';
import { Observable, Subject } from 'rxjs';
import { Masterdata } from '@main/masterdata';
import { Container } from '@shipping/models/container';
import { ContainerService } from '@shipping/services/container.service';
import { ShipperService } from '@shipping/services/shipper.service';
import { Shipper } from '@shipping/models/shipper';
import { FreightpaymentService } from '@shipping/services/freightpayment.service';
import { FreightPayment, emptyFreightPayment } from '@shipping/models/freightpayment';
import { Store, select } from '@ngrx/store';
import { addUrl, deleteContainerList, addSearchRequest, addContainers } from '@shared/store/actions/container.actions';
import { map, takeUntil, filter, take, flatMap } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { ContainerSearchRequest } from '@shipping/models/container-search-request';

@Component({
    selector: 'app-container-page',
    templateUrl: './container-page.component.html',
    styleUrls: ['./container-page.component.scss'],
})
export class ContainerPageComponent implements OnInit, OnDestroy, AfterViewInit {
    private unsubscribe$ = new Subject<void>();
    masterdata: Masterdata;
    projectTypes: string[];
    container$: Observable<Container[]>;
    shipper$: Observable<Shipper[]>;
    freightpayment$: Observable<FreightPayment[]>;
    isContainerTableVisible: boolean = true;

    constructor(
        private pageTitleService: PageTitleService,
        private masterdataService: MasterdataService,
        private containerService: ContainerService,
        protected shipperService: ShipperService,
        protected freightpaymentService: FreightpaymentService,
        private store: Store<any>,
        private router: Router,
    ) {
        this.shipper$ = shipperService.getAll();
        this.freightpayment$ = freightpaymentService.getAll().pipe(
            map(data => {
                return [...[emptyFreightPayment], ...data];
            }),
        );

        router.events
            .pipe(
                filter(e => e instanceof NavigationEnd),
                take(1),
                flatMap(navigationEnd => {
                    const navigation = router.getCurrentNavigation();
                    if (navigation.extras && navigation.extras.queryParams) {
                        const containerSearchRequest: ContainerSearchRequest = {
                            containerNumber: navigation.extras.queryParams.containerNumber,
                            inHouseFrom: navigation.extras.queryParams.inHouseFrom,
                            inHouseTo: navigation.extras.queryParams.inHouseTo,
                            projectNumber: navigation.extras.queryParams.projectNumber,
                            subNumber: navigation.extras.queryParams.subNumber,
                            projectType: navigation.extras.queryParams.projectType,
                        };

                        this.store.dispatch(
                            addSearchRequest({
                                searchRequest: containerSearchRequest,
                            }),
                        );
                        this.store.dispatch(deleteContainerList());
                        return this.containerService.search(containerSearchRequest);
                    } else {
                        return [];
                    }
                }),
            )
            .subscribe(containers => {
                this.store.dispatch(addContainers({ containers: containers }));
            });

        this.container$ = this.store.pipe(takeUntil(this.unsubscribe$), select('container')).pipe(
            map(data => {
                this.isContainerTableVisible = true;
                return data.containerList;
            }),
        );
    }

    ngOnInit() {
        this.store.dispatch(addUrl({ url: this.router.url }));
        this.pageTitleService.setTitle('menu.shipping.containerSearch');
        this.masterdataService
            .loadMasterdata()
            .pipe(take(1))
            .subscribe(data => {
                this.masterdata = data;
                this.projectTypes = data.projectTypes;
            });
    }

    onSearch(searchRequest: ContainerSearchRequest): void {
        this.store.dispatch(addSearchRequest({ searchRequest: searchRequest }));
        this.containerService
            .search(searchRequest)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(containers => {
                this.store.dispatch(addContainers({ containers: containers }));
            });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngAfterViewInit() {}
}
