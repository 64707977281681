import { createSelector } from '@ngrx/store';
import { AppState } from '../app-state';
import { ShippingdialogState } from '../reducers/shipping-dialog.reducer';

export const selectShippingDialog = (state: AppState) => state.shippingDialog;

/**
 * bestellpositionResponse
 */
export const selectBestellpositionResponseContainer = createSelector(
    selectShippingDialog,
    (state: ShippingdialogState) => {
        return state && state.bestellpositionResponse && state.bestellpositionResponse.container;
    },
);

export const selectProjectFromBestellpositionResponse = createSelector(
    selectShippingDialog,
    (state: ShippingdialogState) => {
        return state && state.bestellpositionResponse && state.bestellpositionResponse.project;
    },
);

export const selectProjectIdFromBestellpositionResponse = createSelector(
    selectShippingDialog,
    (state: ShippingdialogState) => {
        return (
            state &&
            state.bestellpositionResponse &&
            state.bestellpositionResponse.project &&
            state.bestellpositionResponse.project.id
        );
    },
);

/**
 * lieferpositionRequest
 */
export const selectLieferpositionRequest = createSelector(selectShippingDialog, (state: ShippingdialogState) => {
    return state.lieferpositionRequest;
});

/**
 * lieferpositionen
 */
export const selectLieferpositionen = createSelector(selectShippingDialog, (state: ShippingdialogState) => {
    return state.lieferpositionen;
});

export const selectBestellpositionIdsInLieferpositionen = createSelector(
    selectShippingDialog,
    (state: ShippingdialogState) => {
        const bestellpositionIds: number[] = [];
        if (state.lieferpositionen && state.lieferpositionen.length > 0) {
            state.lieferpositionen.forEach(data => {
                const index = bestellpositionIds.findIndex(
                    bestellpositionId => bestellpositionId === data.bestellpositionId,
                );
                if (index === -1) {
                    bestellpositionIds.push(data.bestellpositionId);
                }
            });
        }
        return bestellpositionIds;
    },
);

/**
 * sizeQuantities
 */
export const selectFirstLieferpositionId = createSelector(selectShippingDialog, (state: ShippingdialogState) => {
    let lieferpositionId: number = null;
    if (state.sizeQuantities && state.sizeQuantities.length > 0) {
        lieferpositionId = state.sizeQuantities[0].shippingPositionId;
    }
    return lieferpositionId;
});
