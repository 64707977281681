import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SuccessMessageService {
  constructor(protected toastr: ToastrService, protected translate: TranslateService) {}

  showSuccessMsg(key: string): void {
    const title: string = this.translate.instant('notifications.success');
    const msg: string = this.translate.instant(key);
    this.toastr.success(msg, title);
  }

  showInfoMsg(key: string): void {
    const title: string = this.translate.instant('notifications.info');
    const msg: string = this.translate.instant(key);
    this.toastr.warning(msg, title);
  }
}
