import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Todo } from './todo';
import { environment } from 'environments/environment';
import { Subject, Observable } from 'rxjs';
import { ConfigurationParameter } from '../administration/configuration/ConfigurationParameter';
import { ErrorMessageService } from '@core/service/error-message.service';
import { SuccessMessageService } from '@core/service/success-message.service';

@Injectable()
export class TodoService {
  private todoNotification = new Subject<any>();
  private configParamsSubject = new Subject<any>();

  constructor(
    protected toastr: ToastrService,
    protected translate: TranslateService,
    protected authService: AuthService,
    protected errorMessageService: ErrorMessageService,
    protected successMessageService: SuccessMessageService,
    private httpClient: HttpClient,
  ) {}

  getTodosByUserId(id: number) {
    return this.httpClient
      .get<Todo[]>(`${environment.apiBaseUrl}/todo?user=` + id, { withCredentials: true })
      .toPromise()
      .then(data => {
        return data;
      })
      .catch(err => {
        return this.errorMessageService.handleError(err);
      });
  }

  updateTodo(todo: Todo): Promise<any> {
    return this.httpClient
      .post<any>(`${environment.apiBaseUrl}/todo`, todo, { withCredentials: true })
      .toPromise()
      .then(data => {
        this.successMessageService.showSuccessMsg('generic.success.update');
        return data;
      })
      .catch(err => {
        this.errorMessageService.showErrorMsg('generic.problems.update');
        return this.errorMessageService.handleError(err);
      });
  }

  updateTodoNotification(todo: Todo[]) {
    this.todoNotification.next(todo);
  }

  getTodoNotification(): Observable<any> {
    return this.todoNotification.asObservable();
  }

  getCPtodoConfigurationSize(): Observable<any> {
    return this.configParamsSubject.asObservable();
  }

  sendCPtodoConfigurationSize(configParam: ConfigurationParameter) {
    this.configParamsSubject.next(configParam);
  }
}
