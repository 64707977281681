import { Component, OnInit } from '@angular/core';
import { AgRendererComponent, ICellRendererAngularComp } from '@ag-grid-community/angular';
import { IAfterGuiAttachedParams } from '@ag-grid-enterprise/all-modules';

@Component({
    selector: 'app-ag-grid-checkbox',
    templateUrl: './ag-grid-checkbox.component.html',
    styleUrls: ['./ag-grid-checkbox.component.scss'],
})
export class AgGridCheckboxComponent implements ICellRendererAngularComp {
    params;
    checkBox: any;

    agInit(params: any): void {
        this.params = params;
        this.checkBox = this.params.onCheckBoxClick || null;
    }

    afterGuiAttached(params?: IAfterGuiAttachedParams): void {}

    refresh(params?: any): boolean {
        params.api.refreshCells(params);
        return false;
    }

    onCheckBoxClick($event) {
        if (this.params.onCheckBoxClick instanceof Function) {
            this.params.onCheckBoxClick(this.getParams($event));
        }
    }

    getParams($event) {
        return {
            event: $event,
            rowData: this.params.node.data,
        };
    }
}
