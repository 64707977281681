import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TranslateModule } from '@ngx-translate/core';
import { MainDataTableComponent } from './main-data-table.component';
import {
    MatIconModule,
    MatTooltipModule,
    MatSelectModule,
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserSettingsService } from '../../user/settings/user-settings.service';
import { F42DataTableFilterComponent } from './filter/f42-data-table-filter.component';
import { F42DataTableLimitComponent } from './limit/f42-data-table-limit.component';
import { F42DataTableColumnVisibilityComponent } from './columnVisibility/f42-data-table-column-visibility.component';
import { F42DataTableExcelExportComponent } from './excelExport/f42-data-table-excel-export.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        NgxDatatableModule,
        MatIconModule,
        MatTooltipModule,
        MatSelectModule,
    ],
    declarations: [
        MainDataTableComponent,
        F42DataTableFilterComponent,
        F42DataTableLimitComponent,
        F42DataTableColumnVisibilityComponent,
        F42DataTableExcelExportComponent,
    ],
    exports: [
        MainDataTableComponent,
        NgxDatatableModule,
        F42DataTableFilterComponent,
        F42DataTableLimitComponent,
        F42DataTableColumnVisibilityComponent,
        F42DataTableExcelExportComponent,
    ],
    providers: [UserSettingsService],
})
export class MainDataTableModule {}
