import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/user/user';
import PerfectScrollbar from 'perfect-scrollbar';
import { environment } from '../../environments/environment';
import { ConfigurationService } from '../administration/configuration/configuration.service';
import { AuthService } from '../auth/auth.service';
import { MenuItems } from '../core/menu/menu-items/menu-items';
import { PageTitleService } from '../core/page-title/page-title.service';
import { MasterdataService } from './masterdata.service';
import { EinmalHinweisService } from '@app/einmal-hinweis/service/einmal-hinweis.service';

declare var $: any;

const screenfull = require('screenfull');

export interface Lang {
  value: string;
  img: string;
}

@Component({
  selector: 'app-main-material',
  templateUrl: './main-material.html',
  styleUrls: ['./main-material.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MainComponent implements OnInit {
  @ViewChild('sidenav', { static: true }) sidenav;

  public header: string; // Title
  public currentLang = 'de';
  private url: string;
  public themeSkinColor: any = 'light';
  public sidebarClosed = false;
  public isMobile = false;

  public isFullscreen = false;

  public _opened = true;
  public _mode = 'push';
  public _closeOnClickOutside = false;
  public _showBackdrop = false;

  public isTestSystem: boolean;
  public user: User;

  public _toggleOpened(): void {
    this._opened = !this._opened;
  }

  constructor(
    public menuItems: MenuItems,
    private pageTitleService: PageTitleService,
    public translate: TranslateService,
    private router: Router,
    private media: MediaObserver,
    private authService: AuthService,
    private mastardataService: MasterdataService,
    private einmalHinweisService: EinmalHinweisService,
    private configurationService: ConfigurationService,
  ) {
    this.isTestSystem = !environment.production;
  }

  ngOnInit() {
    this.pageTitleService.title.subscribe(val => (this.header = val));

    this.router.events.subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });

    if (!this.url || this.url.indexOf('session') === -1) {
      const elemSidebar = <HTMLElement>document.querySelector('.sidebar-container');

      /** Perfect scrollbar for sidebar menu **/
      if (window.matchMedia(`(min-width: 960px)`).matches) {
        const ps = new PerfectScrollbar(elemSidebar, {
          wheelSpeed: 2,
          wheelPropagation: true,
          minScrollbarLength: 20,
        });
        ps.update();
      }

      const content = <HTMLElement>document.querySelector('.chankya-base-container');

      /** Perfect scrollbar for main content **/
      const contentPs = new PerfectScrollbar(content);
      contentPs.update();
    }

    if (this.media.isActive('xs') || this.media.isActive('sm')) {
      this._mode = 'over';
      this._closeOnClickOutside = true;
      this._showBackdrop = true;
      this._opened = false;
      this.sidebarClosed = false;
    }

    this.media.media$.subscribe(change => {
      const isMobile = change.mqAlias === 'xs' || change.mqAlias === 'sm';

      this.isMobile = isMobile;
      this._mode = isMobile ? 'over' : 'push';
      this._closeOnClickOutside = isMobile;
      this._showBackdrop = isMobile;
      this._opened = !isMobile;
      this.sidebarClosed = false;
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && this.isMobile) {
        this.sidenav.close();
      }
    });

    // Add slideDown animation to dropdown
    $('.dropdown').on('show.bs.dropdown', function(e) {
      $(this)
        .find('.dropdown-menu')
        .first()
        .stop(true, true)
        .slideDown(500);
    });

    // Add slideUp animation to dropdown
    $('.dropdown').on('hide.bs.dropdown', function(e) {
      $(this)
        .find('.dropdown-menu')
        .first()
        .stop(true, true)
        .slideUp(500);
    });

    // load all Masterdata
    this.mastardataService.loadMasterdataPromise();

    // load all ConfigurationParams
    this.configurationService.loadConfigurationParams();

    this.user = this.authService.getUser();

    // Lade alle bestätigten Einmal-Hinweise des Benutzers
    this.einmalHinweisService.getAllEhKeysFromUser();
  }

  onLogout() {
    this.authService.logout();
  }

  onLangChange(currentLang: string) {
    this.translate.use(currentLang);
    this.pageTitleService.refresh();
  }

  toggleFullscreen() {
    if (screenfull.enabled) {
      screenfull.toggle();
      this.isFullscreen = !this.isFullscreen;
    }
  }

  sidebarClosedFunction() {
    this.sidebarClosed = !this.sidebarClosed;
  }

  onActivate(e, scrollContainer) {
    scrollContainer.scrollTop = 0;
  }
}
