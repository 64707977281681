import { createReducer, on } from '@ngrx/store';
import * as ContainerActions from '../actions/container.actions';
import { Container } from '@shipping/models/container';
import { ContainerSearchRequest } from '@shipping/models/container-search-request';

export interface ContainerState {
    containerList: Container[];
    searchRequest: ContainerSearchRequest;
    url: string;
}

export const initialState = {
    containerList: [],
    searchRequest: {},
    url: ''
};

const _containerReducer = createReducer(
    initialState,
    on(ContainerActions.loadContainers, state => ({
        ...state,
        containerList: state.containerList
    })),
    on(ContainerActions.addContainers, (state, { containers: container }) => ({
        ...state,
        containerList: container
    })),
    on(ContainerActions.addSearchRequest, (state, { searchRequest }) => ({
        ...state,
        searchRequest: searchRequest
    })),
    on(ContainerActions.addUrl, (state, { url }) => ({
        ...state,
        url: url
    })),
    on(ContainerActions.deleteByurl, (state, { url }) => {
        if (url === state.url) {
            state = initialState;
        }
        return state;
    }),
    on(ContainerActions.deleteContainerList, state => ({
        ...state,
        containerList: []
    }))
);

export function containerReducer(state, action) {
    return _containerReducer(state, action);
}
