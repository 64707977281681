import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app-state';
import { TableStoreModel } from './table-store-model';
import { ADD_TABLE, REMOVE_TABLE } from '../reducers/table.reducer';

@Injectable()
export class TableStoreService {

    constructor(private store: Store<AppState>) {
    }

    addTableByUrl(url: string, table: any[], filter: any, scrollPos: any) {
        this.addTable({
            url: url,
            tableGroupValue: table,
            filter: filter,
            scrollPos: scrollPos,
        });
    }

    removeTableByUrl(url: string) {
        this.removeTable({
            url: url,
            tableGroupValue: {},
            filter: {},
            scrollPos: {},
        });
    }

    addTable(table: TableStoreModel) {
        this.store.dispatch({
            type: ADD_TABLE,
            payload: table
        });
    }

    removeTable(table: TableStoreModel) {
        this.store.dispatch({
            type: REMOVE_TABLE,
            payload: table
        });
    }

    findIndexOfUrl(tables: TableStoreModel[], url: string): number {
        return tables.filter(table => !!table).map(table => table.url).indexOf(url);
    }
}
