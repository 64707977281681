import { Component, OnInit } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams, IAfterGuiAttachedParams } from '@ag-grid-enterprise/all-modules';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements IHeaderAngularComp {
    params: any;

    constructor() {}
    agInit(params: IHeaderParams): void {
        this.params = params;
    }

    onHeaderIconClick($event) {
        if (this.params.onHeaderIconClick instanceof Function) {
            this.params.onHeaderIconClick(this.getParams($event));
        }
    }

    afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}

    getParams($event) {
        return {
            event: $event,
        };
    }
}
