import { Injectable } from '@angular/core';
import { AppState } from '../app-state';
import { Store } from '@ngrx/store';
import { Tab } from './tab';
import { ADD_TAB, REMOVE_TAB, UPDATE_TAB, CHANGE_TAB_URL } from '../reducers/tab.reducer';
import { CHANGE_ACTIVE_TAB } from '../reducers/active-tab.reducer';
import { FormService } from '../form/form.service';
import { FormGroup } from '@angular/forms';
import { TableStoreService } from '../table/table-store.service';
import { ProjectStoreService } from '../project/project-store.service';
import * as ContainerActions from '../actions/container.actions';
import * as ShippingDialogActions from '../actions/shipping-dialog.actions';
import * as PackinglistActions from '../actions/packinglist.actions';

@Injectable()
export class TabStoreService {
    constructor(
        private store: Store<AppState>,
        private formService: FormService,
        private tableStoreService: TableStoreService,
        private projectStoreService: ProjectStoreService,
    ) {}

    addTabByUrl(url: string, tabTitle: string) {
        const tab = {
            id: null,
            name: url.replace(/\//g, ' ').trim(),
            url: url,
            nameVisible: tabTitle,
            suffix: '',
        };
        this.addTabStore(tab);
        this.changeActiveTabStore(tab);
    }

    removeTabByUrl(url: string) {
        const tab = {
            id: null,
            name: url.replace(/\//g, ' ').trim(),
            url: url,
            nameVisible: this.setVisibleName(url),
            suffix: '',
        };
        this.removeTabStore(tab);
        this.formService.removeFormByUrl(tab.url);
        this.tableStoreService.removeTableByUrl(tab.url);
        this.projectStoreService.removeProjectByUrl(url);
        this.store.dispatch(ContainerActions.deleteByurl({ url: url }));
        this.store.dispatch(ShippingDialogActions.deleteByurl({ url: url }));
        this.store.dispatch(PackinglistActions.deleteByurl({ url: url }));
    }

    changeUrlById(id: number, url: string, name: string) {
        const tab = {
            id: id,
            name: name,
            url: url,
            nameVisible: null,
            suffix: null,
        };
        this.changeUrlStore(tab);
    }

    changeActiveTab(tab: Tab): void {
        this.changeActiveTabStore(tab ? tab : null);
    }

    private addTabStore(tab: Tab) {
        this.store.dispatch({
            type: ADD_TAB,
            payload: tab,
        });
    }

    private removeTabStore(tab: Tab) {
        this.store.dispatch({
            type: REMOVE_TAB,
            payload: tab,
        });
    }

    private changeActiveTabStore(tab: Tab) {
        this.store.dispatch({
            type: CHANGE_ACTIVE_TAB,
            payload: tab,
        });
    }

    private changeUrlStore(tab: Tab) {
        this.store.dispatch({
            type: CHANGE_TAB_URL,
            payload: tab,
        });
    }

    changeStateOfTabByAction(form: FormGroup, isSubmit: boolean, url: string) {
        if (form.dirty) {
            // form.dirty && !isSubmit
            this.formService.addFormByUrl(url, form);
        }
        // else if (isSubmit) {
        //     this.removeTabByUrl(url);
        // }
    }

    private setVisibleName(url: string): string {
        let localurl: string = url.replace(/\//g, ' ').trim();

        if (url.match('[?]')) {
            localurl = url
                .substring(0, url.indexOf('?'))
                .replace(/\//g, ' ')
                .trim();
        }
        return localurl;
    }

    public addSuffixByUrl(suffix: string, url: string) {
        const tab = {
            id: null,
            name: null,
            url: url,
            nameVisible: null,
            suffix: suffix,
        };
        this.updateTab(tab);
    }
    updateTab(tab: Tab) {
        this.store.dispatch({
            type: UPDATE_TAB,
            payload: tab,
        });
    }
}
