import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { AuthService } from './auth.service';
import 'rxjs/add/operator/do';
import { BaseService } from '../core/service/base.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { mergeMap, materialize, delay, dematerialize, map } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { StepManagementRowDTO } from '@project/models/step-management-row-dto';

@Injectable()
export class Interceptor extends BaseService implements HttpInterceptor {
    constructor(
        protected toastr: ToastrService,
        protected translate: TranslateService,
        protected authService: AuthService,
    ) {
        super(toastr, translate, authService);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { url, method, headers, body } = request;

        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        // do stuff with response if you want
                    }
                },
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 400 && err !== null && err.error !== null) {
                            switch (err.error.errorCode) {
                                case 'TOO_MANY_RESULTS': {
                                    if (err.error && err.error.errorCode) {
                                        let errorTitle = 'notifications.error';
                                        if (err.error.errorField) {
                                            errorTitle = 'error.' + err.error.errorField;
                                        }
                                        const title = this.translate.instant(errorTitle);
                                        const replaceParam =
                                            err.error.errorInfos && err.error.errorInfos[0].errorParams
                                                ? err.error.errorInfos[0].errorParams[0]
                                                : 'MAX';
                                        const msg = this.translate.instant('error.' + err.error.errorCode, {
                                            limit: replaceParam,
                                        });
                                        BaseService.instance.toastr.warning(msg, title, { enableHtml: true });
                                    }
                                    break;
                                }
                                default: {
                                    if (err.error.errorInfos) {
                                        this.showErrorMsg('error.' + err.error.errorInfos[0].errorCode);
                                    } else {
                                        this.showErrorMsg('error.' + err.error.errorCode);
                                    }
                                    break;
                                }
                            }
                        }
                    }
                },
            ),
        );
    }
}
