import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
import * as i2 from "@ngx-translate/core";
export class SuccessMessageService {
    constructor(toastr, translate) {
        this.toastr = toastr;
        this.translate = translate;
    }
    showSuccessMsg(key) {
        const title = this.translate.instant('notifications.success');
        const msg = this.translate.instant(key);
        this.toastr.success(msg, title);
    }
    showInfoMsg(key) {
        const title = this.translate.instant('notifications.info');
        const msg = this.translate.instant(key);
        this.toastr.warning(msg, title);
    }
}
SuccessMessageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SuccessMessageService_Factory() { return new SuccessMessageService(i0.ɵɵinject(i1.ToastrService), i0.ɵɵinject(i2.TranslateService)); }, token: SuccessMessageService, providedIn: "root" });
