import { Injectable } from '@angular/core';
import { BaseService } from '@core/service/base.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@auth/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Lieferposition } from '../models/lieferposition';
import { UpdateShippingPositionRequest } from '@shipping/models/update-shipping-position-request';
import { CreateLieferpositionRequest } from '@shipping/models/create-lieferposition-request';
import { SizeQuantityResponse } from '@shipping/models/size-quantity-response';
import { UpdateGroessenMengenRequest } from '@shipping/models/update-groessen-mengen-request';
import { LieferpositionRequest } from '@shipping/models/lieferposition-request';
import { LieferpositionDto } from '@shipping/models/lieferposition-dto';

@Injectable({
    providedIn: 'root',
})
export class ShippingPositionService extends BaseService {
    private readonly url: string = `${environment.apiBaseUrl}/shippingPosition`;

    constructor(
        protected toastr: ToastrService,
        protected translate: TranslateService,
        protected authService: AuthService,
        private httpClient: HttpClient,
    ) {
        super(toastr, translate, authService);
    }

    /*
    CREATE
     */
    createShippingPosition(createShippingPositionRequest: CreateLieferpositionRequest): Observable<Lieferposition> {
        return this.httpClient
            .post<Lieferposition>(this.url + `/`, createShippingPositionRequest, {
                withCredentials: true,
            })
            .pipe(
                catchError(err => {
                    this.showErrorMsg('generic.problems.update');
                    return this.handleError(err);
                }),
            );
    }

    /*
    READ ALL
    */
    getLieferpositionDTOs(shippingPositionRequest: LieferpositionRequest): Observable<LieferpositionDto[]> {
        const params = new HttpParams().append('purchaseOrderPositions', shippingPositionRequest.toString());

        return this.httpClient
            .get<LieferpositionDto[]>(this.url, {
                withCredentials: true,
                params: params,
            })
            .pipe(
                catchError(err => {
                    this.showErrorMsg('generic.problems.downloadAll');
                    return this.handleError(err);
                }),
            );
    }

    /*
    READ SINGLE
    */
    getSizeQuantities(lieferpositionId: number): Observable<SizeQuantityResponse> {
        return this.httpClient
            .get<SizeQuantityResponse>(this.url + `/` + lieferpositionId + `/sizeQuantities`, { withCredentials: true })
            .pipe(
                catchError(err => {
                    this.showErrorMsg('generic.problems.downloadAll');
                    return this.handleError(err);
                }),
            );
    }

    /*
    SAVE
    */
    saveSizeQuantities(updateGroessenMengenRequest: UpdateGroessenMengenRequest) {
        return this.httpClient
            .post<any>(
                this.url + `/` + updateGroessenMengenRequest.id + `/sizeQuantities`,
                updateGroessenMengenRequest,
                { withCredentials: true },
            )
            .pipe(
                map(data => {
                    this.showSuccessMsg('generic.success.update');
                    return data;
                }),
                catchError(err => {
                    this.showErrorMsg('generic.problems.update');
                    return this.handleError(err);
                }),
            );
    }

    saveShippingPosition(updateShippingPosition: UpdateShippingPositionRequest): Observable<Lieferposition> {
        return this.httpClient
            .post<Lieferposition>(this.url + `/` + updateShippingPosition.id, updateShippingPosition, {
                withCredentials: true,
            })
            .pipe(
                map(data => {
                    this.showSuccessMsg('generic.success.update');
                    return data;
                }),
                catchError(err => {
                    this.showErrorMsg('generic.problems.update');
                    return this.handleError(err);
                }),
            );
    }

    /*
    DELETE
    */
    deleteLieferposition(id: number): Observable<any> {
        return this.httpClient
            .delete<any>(this.url + `/` + id, {
                withCredentials: true,
            })
            .pipe(
                catchError(err => {
                    this.showErrorMsg('generic.problems.remove');
                    return this.handleError(err);
                }),
            );
    }
}
