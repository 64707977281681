import { Injectable } from '../../../node_modules/@angular/core';
import { ToastrService } from '../../../node_modules/ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '../../../node_modules/@angular/common/http';
import { environment } from '../../environments/environment';
import { Masterdata } from './masterdata';
import { Observable } from 'rxjs';
import { Port } from '@port/port';

@Injectable()
export class MasterdataService {
    public masterdata: Masterdata;

    constructor(
        protected toastr: ToastrService,
        protected translate: TranslateService,
        protected authService: AuthService,
        private httpClient: HttpClient,
    ) {}

    loadMasterdata(): Observable<Masterdata> {
        return this.httpClient.get<Masterdata>(`${environment.apiBaseUrl}/masterdata`, { withCredentials: true });
    }

    loadMasterdataPromise(): Promise<Masterdata> {
        return this.httpClient
            .get<Masterdata>(`${environment.apiBaseUrl}/masterdata`, {
                withCredentials: true,
            })
            .toPromise()
            .then(data => {
                this.masterdata = data;
                return data;
            });
    }

    getMasterdata(): Masterdata {
        if (this.masterdata === undefined) {
            this.loadMasterdataPromise();
        }
        return this.masterdata;
    }

    getPortWithId(portId: number): Port {
        return this.masterdata.ports.find(port => port.id === portId) || new Port();
    }
}
