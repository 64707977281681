import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '@core/page-title/page-title.service';
import { MasterdataService } from '@main/masterdata.service';
import { Masterdata } from '@main/masterdata';
import { Bestellposition } from '@shipping/models/bestellposition';
import { Lieferposition } from '@shipping/models/lieferposition';
import { take } from 'rxjs/operators';
import { Router} from '@angular/router';
import { BestellpositionRequest } from '@shipping/models/bestellposition-request';
import { Store } from '@ngrx/store';
import { addUrl } from '@shared/store/actions/shipping-dialog.actions';
import { Container } from '@shipping/models/container';
import { ContainerService } from '@shipping/services/container.service';
import { ShippingComponentService } from '@shipping/services/shipping-component.service';

@Component({
    selector: 'app-shipping-page',
    templateUrl: './shipping-page.component.html',
    styleUrls: ['./shipping-page.component.scss'],
})
export class ShippingPageComponent implements OnInit {
    masterData: Masterdata;
    projectTypes: string[];
    bestellpositionen: Bestellposition[];
    lieferpositionen: Lieferposition[];
    containers: Container[];
    isContainersLoaded: boolean = false;

    isShippingPositionVisible: boolean = false;
    bestellpositionRequest: BestellpositionRequest;

    constructor(
        private pageTitleService: PageTitleService,
        private masterDataService: MasterdataService,
        protected router: Router,
        private store: Store<{}>,
        private containerService: ContainerService,
        private shippingComponentService: ShippingComponentService
    ) {
        this.containerService
            .getAll()
            .pipe(take(1))
            .subscribe(data => {
                this.containers = data;
                this.isContainersLoaded = true;
            });

        this.bestellpositionRequest = this.router.getCurrentNavigation().extras.state as BestellpositionRequest;

        if (this.bestellpositionRequest != null ) {
            this.onSearch();
        }
    }

    onSearch() {
        this.shippingComponentService
            .versandSuche(this.bestellpositionRequest)
            .pipe(take(1))
            .subscribe();
    }

    ngOnInit() {
        this.store.dispatch(addUrl({ url: this.router.url }));
        this.pageTitleService.setTitle('general.shipping');
        this.masterDataService
            .loadMasterdata()
            .pipe(take(1))
            .subscribe(data => {
                this.masterData = data;
                this.projectTypes = data.projectTypes;
            });
    }
}
