import { Store } from '@ngrx/store';
import * as ProjectReducer from '../reducers/project-reducer';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class ProjectStoreService {
    constructor(store) {
        this.store = store;
        this.project$$ = new BehaviorSubject(null);
        this.project$ = this.project$$.asObservable();
    }
    send(project) {
        this.project$$.next(project);
    }
    addProject(id, project, urlString) {
        const projectStoreModel = {
            id: id,
            url: urlString,
            project: project,
            hasChangesWithoutSaving: false,
        };
        this.dispatchProjectStoreModel(projectStoreModel, ProjectReducer.ADD_PROJECT);
        this.send(project);
    }
    removeProjectByUrl(urlString) {
        const projectStoreModel = {
            id: null,
            url: urlString,
            project: null,
            hasChangesWithoutSaving: null,
        };
        this.dispatchProjectStoreModel(projectStoreModel, ProjectReducer.REMOVE_PROJECT);
    }
    updateProject(project) {
        const projectStoreModel = {
            id: project.id,
            url: null,
            project: project,
            hasChangesWithoutSaving: true,
        };
        this.dispatchProjectStoreModel(projectStoreModel, ProjectReducer.UPDATE_PROJECT);
        this.send(project);
    }
    saveProject(project) {
        const projectStoreModel = {
            id: project.id,
            url: null,
            project: project,
            hasChangesWithoutSaving: false,
        };
        this.dispatchProjectStoreModel(projectStoreModel, ProjectReducer.SAVE_PROJECT);
        this.loadProject(project);
    }
    loadProject(project) {
        const projectStoreModel = {
            id: project.id,
            url: null,
            project: null,
            hasChangesWithoutSaving: null,
        };
        this.dispatchProjectStoreModel(projectStoreModel, ProjectReducer.LOAD_PROJECT);
        this.send(project);
    }
    setProjectToSaved(project) {
        const projectStoreModel = {
            id: project.id,
            url: null,
            project: project,
            hasChangesWithoutSaving: false,
        };
        this.dispatchProjectStoreModel(projectStoreModel, ProjectReducer.REMOVE_HASCHANGESWITHOUTSAVINGS);
        this.send(project);
    }
    clearAllProjects() {
        this.store.dispatch({
            type: ProjectReducer.CLEAR_ALL_PROJECT,
        });
    }
    dispatchProjectStoreModel(projectStoreModel, type) {
        this.store.dispatch({
            type: type,
            payload: projectStoreModel,
        });
    }
    findIndexOfId(projects, id) {
        return projects
            ? projects
                .filter(project => !!project)
                .map(project => project.id)
                .indexOf(id)
            : null;
    }
    findProjectStoreModelWithId(projects, id) {
        return projects ? projects.find(projectStoreModel => projectStoreModel.id === id) : null;
    }
}
ProjectStoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProjectStoreService_Factory() { return new ProjectStoreService(i0.ɵɵinject(i1.Store)); }, token: ProjectStoreService, providedIn: "root" });
