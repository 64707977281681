import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PageTitleService {
    public title: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private key: string;

    constructor(private translate: TranslateService) {}

    setTitle(key: string) {
        const msg: string = this.translate.instant(key);
        this.setKey(key);
        this.title.next(msg);
    }

    setKey(key: string) {
        this.key = key;
    }

    refresh() {
        this.setTitle(this.key);
    }
}
