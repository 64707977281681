import { createAction, props } from '@ngrx/store';
import { F42NgxTableState } from '../reducers/f42-ngrx-table.reducer';

export const loadTableWithUrl = createAction(
    '[F42NgrxTable] Load F42NgrxTable'
);

export const resetTable = createAction('[F42NgrxTable] Reset F42NgrxTable');

export const setTable = createAction(
    '[F42NgrxTable] Set F42NgrxTable',
    props<{ f42NgxTables: F42NgxTableState }>()
);
