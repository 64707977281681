import { createAction, props } from '@ngrx/store';
import { ProjectDTO, ContainerDTO } from '@shipping/models/bestellposition-response';
import { Karton } from '@shipping/models/karton';
import { KartonContent } from '@shipping/models/karton-content';
import { KartonUpdateRequest } from '@shipping/models/karton-update-request';
import { KartonDTO } from '@shipping/models/karton-dto';

export const initPackingList = createAction(
    '[Packinglist] Init Packinglist',
    props<{ project: ProjectDTO; containerDTO: ContainerDTO }>(),
);

/**
 * ALL
 */
export const deleteByurl = createAction('[Packinglist] Delete by url', props<{ url: string }>());

/**
 * project
 */
export const addProject = createAction('[Packinglist] Add Project', props<{ project: ProjectDTO }>());

/**
 * containerDTO
 */
export const addContainerDTO = createAction('[Packinglist] Add ContainerDTO', props<{ containerDTO: ContainerDTO }>());

/**
 * Customer Shipmode und Division
 */
export const loadCustomerShipModeAndDivison = createAction(
    '[Packinglist] Load Customer Shipmode and Division',
    props<{ projectId: number; containerId: number; kundenauftragsnummer: string }>(),
);

export const setCustomerShipModeAndDivisonSuccessfilly = createAction(
    '[Packinglist] Load Customer Shipmode and Division Sucessfully',
    props<{ shipmode: string; division: string }>(),
);

export const setCustomerShipModeAndDivision = createAction(
    '[Packinglist] Set Customer Shipmode and Division',
    props<{
        projectId: number;
        containerId: number;
        kundenauftragsnummer: string;
        shipmode: string;
        division: string;
    }>(),
);

/**
 * kartons
 */
export const loadKartons = createAction(
    '[Packinglist] Load Kartonlist',
    props<{ projectId: number; containerId: number; kundenauftragsnummer: string }>(),
);

export const loadKartonsIfNotExists = createAction(
    '[Packinglist] Load Kartons if not exists',
    props<{ projectId: number; containerId: number; kundenauftragsnummer: string }>(),
);

export const loadKartonsSuccessfully = createAction(
    '[Packinglist] Load Kartons successfuly',
    props<{ kartons: KartonDTO[] }>(),
);
export const deleteKarton = createAction('[Packinglist] Delete Karton', props<{ kartonId: number }>());
export const deleteSelectedKartons = createAction(
    '[PackingList] Delete Selected Kartons',
    props<{ kartonIds: number[] }>(),
);
export const duplicateKarton = createAction('[Packinglist] Duplicate Karton', props<{ karton: Karton }>());

/**
 *  Print Cbox
 */
export const selectAllKartonsForPrint = createAction(
    '[Packinglist] Select all kartons for print',
    props<{ projectId: number; containerId: number }>(),
);
export const selectAllKartonsForPrintSuccesfully = createAction(
    '[Packinglist] Select all kartons for print Succesfully',
    props<{ kartons: KartonDTO[] }>(),
);

/**
 * detailKarton
 */
export const addDetailKarton = createAction('[Packinglist] Add detailKarton', props<{ karton: Karton }>());

export const addDetailKartonSuccesfully = createAction(
    '[Packinglist] Add detailKarton successfully',
    props<{ karton: Karton }>(),
);
export const deleteDetailKarton = createAction('[Packinglist] Delete detailKarton');
export const saveDetailKarton = createAction(
    '[Packinglist] Save detailKarton',
    props<{ kartonUpdateRequest: KartonUpdateRequest }>(),
);

/**
 * kartonContent
 */
export const loadEmptyKartonContent = createAction(
    '[Packinglist] Load empty KartonContent',
    props<{ projectId: number; containerId: number; kunu: string }>(),
);
export const loadEmptyKartonContentSuccessfully = createAction(
    '[Packinglist] Load empty KartonContent successfully',
    props<{ kartonContent: KartonContent }>(),
);
export const loadExistingKartonContent = createAction(
    '[Packinglist] Load existing KartonContent',
    props<{ kartonId: number }>(),
);
export const loadExistingKartonContentSuccessfully = createAction(
    '[Packinglist] Load existing KartonContent successfully',
    props<{ kartonContent: KartonContent }>(),
);
export const updateKartonContent = createAction(
    '[Packinglist] Update KartonContent',
    props<{ kartonContent: KartonContent }>(),
);
export const deleteKartonContent = createAction('[Packinglist] Delete KartonContent');
