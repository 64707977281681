import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, CanLoad, Route, NavigationExtras } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { TabStoreService } from '../shared/store/tab/tab-store.service';

@Injectable()
export class AuthLoginGuard implements CanActivate, CanLoad {
    private hasPermission = false;
    constructor(private router: Router,
        private authService: AuthService,
        private tabsStore: TabStoreService) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const tabTitle = next.data.tabTitle;
        const url = state.url;
        this.authService.setNextDataPermission(next.data.permission);
        this.authService.setRedirectUrl(url);

        if (this.authService.hasPermissions(next.data.permission)) {
            this.hasPermission = true;
        } else {
            if (this.authService.isAuthenticated()) {
                const params: NavigationExtras = { queryParams: { permissionDenied: 'true' } };
                this.router.navigate(['session/loginone'], params);
            } else {
                this.router.navigate(['session/loginone']);
            }

            return false;
        }

        return this.checkLogin(url, tabTitle);
    }

    canLoad(route: Route): boolean {
        const url = `/${route.path}`;
        return this.checkLogin(url, '');
    }

    checkLogin(url: string, tabTitle: string): boolean {
        this.authService.setRedirectUrl(url);

        if (this.hasPermission && this.authService.isAuthenticated()) {
            if (tabTitle) {
                this.tabsStore.addTabByUrl(url, tabTitle);
            } else {
                this.tabsStore.changeActiveTab(null);
            }

            return true;
        }

        this.router.navigateByUrl('session/loginone');

        return false;
    }
}
