import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../app-state';
import { PackinglistState } from '../reducers/packinglist.reducer';

export const selectPackinglist = (state: AppState) => state.packinglist;

export const selectProjectDTO = createSelector(selectPackinglist, (state: PackinglistState) => {
    return state.project;
});

export const selectContainerDTO = createSelector(selectPackinglist, (state: PackinglistState) => {
    return state.containerDTO;
});

export const selectKartons = createSelector(selectPackinglist, (state: PackinglistState) => {
    return state.kartons;
});

export const selectKartonsAgGrid = createSelector(selectPackinglist, (state: PackinglistState) => {
    return state.kartonsAgGrid;
});

export const selectDetailKarton = createSelector(selectPackinglist, (state: PackinglistState) => {
    return state.detailKarton;
});

export const selectProjectIdAndContainerId = createSelector(selectPackinglist, (state: PackinglistState) => {
    return {
        projectId: state && state.project && state.project.id,
        containerId: state && state.containerDTO && state.containerDTO.id,
        kundenauftragsnummer: state && state.containerDTO && state.containerDTO.kundenauftragsnummer,
    };
});

export const selectKartonContent = createSelector(selectPackinglist, (state: PackinglistState) => {
    return state.kartonContent;
});

export const selectCustomerShipMode = createSelector(selectPackinglist, (state: PackinglistState) => {
    return state.customerShippingMode;
});

export const selectCustomerDivision = createSelector(selectPackinglist, (state: PackinglistState) => {
    return state.customerDivsion;
});
