import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from '@ag-grid-community/core';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-mat-checkbox-renderer',
    templateUrl: './mat-checkbox-renderer.component.html',
})
export class MatCheckboxRendererComponent implements AgRendererComponent {
    params: any;

    refresh(params: any): boolean {
        params.data.amount++;
        params.data.cbox = params.value;
        params.api.refreshCells(params);
        return false;
    }

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
        throw new Error('Method not implemented.');
    }
}
