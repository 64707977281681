import { Action, createAction, props } from '@ngrx/store';
import { CalculationSearchRequest } from '@app/calculation/models/calculation-search-request';
import { CalculationSearchResponse } from '@app/calculation/models/calculation-search-response';
import { CalculationProjectPositions } from '@app/calculation/models/calcilation-project-position';

export const initCalculation = createAction(
    '[Calculation] Init Calculation',
    props<{ search: CalculationSearchRequest }>(),
);

export const addSearchRequest = createAction(
    '[Calculation] AddSearchRequest',
    props<{ searchRequest: CalculationSearchRequest }>(),
);

export const addProjectPositions = createAction(
    '[Calculation] AddProjectPositions',
    props<{ projectPositions: CalculationProjectPositions[] }>(),
);

export const setSelectedPosition = createAction(
    '[Calculation] setSelectedPosition',
    props<{ selectedPosition: number }>(),
);

export const clearCalculation = createAction('[Calculation] clearCalculation', props<{}>());
