import { Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { AuthLoginGuard } from './session/auth-login.guard';
import { TodosComponent } from './todos/todos.component';
import { Permissions } from './auth/enum/permissions.enum';
const SHIPPING = 'shipping';
const ɵ0 = () => import("./dashboard/dashboard.module.ngfactory").then(m => m.DashboardModuleNgFactory), ɵ1 = () => import("./company/company.module.ngfactory").then(m => m.CompanyModuleNgFactory), ɵ2 = () => import("./project/project.module.ngfactory").then(m => m.ProjectModuleNgFactory), ɵ3 = () => import("./workflow/workflow.module.ngfactory").then(m => m.WorkflowModuleNgFactory), ɵ4 = () => import("./reporting/reporting.module.ngfactory").then(m => m.ReportingModuleNgFactory), ɵ5 = () => import("./user/user.module.ngfactory").then(m => m.UserModuleNgFactory), ɵ6 = () => import("./draw/draw-panel.module.ngfactory").then(m => m.DrawPanelModuleNgFactory), ɵ7 = () => import("./administration/administration.module.ngfactory").then(m => m.AdministrationModuleNgFactory), ɵ8 = {
    tabTitle: 'general.todos',
    permission: Permissions.READ_TODO,
}, ɵ9 = () => import("./nos/nos.module.ngfactory").then(m => m.NosModuleNgFactory), ɵ10 = () => import("./shipping/shipping.module.ngfactory").then(m => m.ShippingModuleNgFactory), ɵ11 = () => import("./calculation/calculation.module.ngfactory").then(m => m.CalculationModuleNgFactory), ɵ12 = () => import("./salesman/salesman.module.ngfactory").then(m => m.SalesmanModuleNgFactory), ɵ13 = () => import("./session/session.module.ngfactory").then(m => m.SessionModuleNgFactory);
const routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: '',
        component: MainComponent,
        children: [
            {
                path: 'dashboard',
                loadChildren: ɵ0,
            },
            {
                path: 'masterdata/company',
                loadChildren: ɵ1,
            },
            {
                path: 'project',
                loadChildren: ɵ2,
            },
            {
                path: 'workflow',
                loadChildren: ɵ3,
            },
            {
                path: 'reporting',
                loadChildren: ɵ4,
            },
            {
                path: 'user',
                loadChildren: ɵ5,
            },
            {
                path: 'drawPanel',
                loadChildren: ɵ6,
            },
            {
                path: 'administration',
                loadChildren: ɵ7,
            },
            {
                path: 'todos',
                component: TodosComponent,
                data: ɵ8,
                canActivate: [AuthLoginGuard],
            },
            {
                path: 'nos',
                loadChildren: ɵ9,
            },
            {
                path: SHIPPING,
                loadChildren: ɵ10,
            },
            {
                path: 'calculation',
                loadChildren: ɵ11,
            },
            {
                path: 'salesman',
                loadChildren: ɵ12,
            },
        ],
    },
    {
        path: '',
        children: [
            {
                path: 'session',
                loadChildren: ɵ13,
            },
        ],
    },
];
export class AppRoutingModule {
}
AppRoutingModule.SHIPPING_URL = SHIPPING;
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13 };
