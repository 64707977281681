import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TodoService } from './todo.service';
import { AuthService } from '../auth/auth.service';
import { PageTitleService } from '../core/page-title/page-title.service';
import { Todo } from './todo';

@Component({
    selector: 'app-todos',
    templateUrl: './todos.component.html',
    styleUrls: ['./todos.component.scss']
})
export class TodosComponent implements OnInit, AfterViewInit {
    public todos: Todo[] = [];

    constructor(private todoService: TodoService,
        private authService: AuthService,
        private pageTitleService: PageTitleService
    ) { }

    ngOnInit() {
        this.pageTitleService.setTitle('general.todos');
        if (this.authService.getUser()) {
            this.todoService.getTodosByUserId(this.authService.getUser().id).then(data => this.todos = data);
        }
    }

    ngAfterViewInit() {

    }

    changeCheckbox(todo) {
        const doneDate: Date = todo.done ? null : new Date();
        todo.done = doneDate;
        this.todoService.updateTodo(todo).then(data => {
            const index = this.todos.findIndex(f => f.id === data.id);
            this.todos[index] = data;
            const todoNotifications = this.todos.filter(t => !t.done);
            this.todoService.updateTodoNotification(todoNotifications);
        }).catch(fail => {
            this.todoService.getTodosByUserId(this.authService.getUser().id).then(data => this.todos = data);
            console.error(fail);
        });
    }

}
