import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../auth/auth.service';
import { ConfigurationParameter } from './ConfigurationParameter';
import { environment } from 'environments/environment';
import { Subject, Observable } from 'rxjs';
import { TodoService } from '../../todos/todo.service';
import { ErrorMessageService } from '@core/service/error-message.service';

@Injectable()
export class ConfigurationService {
  private configParamsSubject = new Subject<any>();
  private _configParams: ConfigurationParameter[];

  constructor(
    private httpClient: HttpClient,
    protected toastrService: ToastrService,
    protected translate: TranslateService,
    protected authService: AuthService,
    protected errorMessageService: ErrorMessageService,
    private todoService: TodoService,
  ) {}

  getAllConfigParams(): Promise<ConfigurationParameter[]> {
    return this.httpClient
      .get<ConfigurationParameter[]>(`${environment.apiBaseUrl}/configuration`, { withCredentials: true })
      .toPromise()
      .then(data => {
        return data;
      })
      .catch(err => {
        this.errorMessageService.showErrorMsg('generic.problems.downloadAll');
        return this.errorMessageService.handleError(err);
      });
  }

  saveAll(configParams: ConfigurationParameter[]): Promise<ConfigurationParameter[]> {
    return this.httpClient
      .post<ConfigurationParameter[]>(`${environment.apiBaseUrl}/configuration`, configParams, {
        withCredentials: true,
      })
      .toPromise()
      .then(data => {
        this.todoService.sendCPtodoConfigurationSize(data.find(param => param.name === 'TODO_NOTIFICATION_SIZE'));
        return data;
      })
      .catch(err => {
        this.errorMessageService.showErrorMsg('generic.problems.downloadAll');
        return this.errorMessageService.handleError(err);
      });
  }

  loadConfigurationParams() {
    this.getAllConfigParams().then(data => {
      this._configParams = data;
      this.configParamsSubject.next(data);
      this.todoService.sendCPtodoConfigurationSize(data.find(param => param.name === 'TODO_NOTIFICATION_SIZE'));
    });
  }

  public get configurationParameter(): ConfigurationParameter[] {
    return this._configParams;
  }

  sendConfigParams(configParams: ConfigurationParameter[]) {
    this.configParamsSubject.next(configParams);
  }

  getConfigParams(): Observable<any> {
    return this.configParamsSubject.asObservable();
  }
}
