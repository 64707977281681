import { Injectable, ɵEMPTY_ARRAY } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    loadKartons,
    loadKartonsIfNotExists,
    loadKartonsSuccessfully,
    initPackingList,
    addProject,
    deleteDetailKarton,
    addDetailKarton,
    addDetailKartonSuccesfully,
    loadEmptyKartonContent,
    loadEmptyKartonContentSuccessfully,
    loadExistingKartonContent,
    loadExistingKartonContentSuccessfully,
    deleteKartonContent,
    saveDetailKarton,
    deleteKarton,
    addContainerDTO,
    duplicateKarton,
    selectAllKartonsForPrint,
    selectAllKartonsForPrintSuccesfully,
    deleteSelectedKartons,
    loadCustomerShipModeAndDivison,
    setCustomerShipModeAndDivisonSuccessfilly,
    setCustomerShipModeAndDivision,
} from '../actions/packinglist.actions';
import { mergeMap, map, catchError, concatMap, withLatestFrom, tap } from 'rxjs/operators';
import { PackingListService } from '@shipping/services/packing-list.service';
import { EMPTY, of, concat } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '../app-state';
import { selectKartons, selectProjectIdAndContainerId } from '../selectors/packinglist.selectors';
import { loadContainerDTO, reloadLieferpositionen } from '../actions/shipping-dialog.actions';
import { initialKarton } from '@shipping/models/karton';

@Injectable()
export class PackinglistEffects {
    constructor(
        private actions$: Actions,
        private packingListService: PackingListService,
        private store: Store<AppState>,
    ) {}

    /**
     * initialisiert die Packinglist
     */
    initPackingList$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(initPackingList),
                tap(action => {
                    this.store.dispatch(addProject({ project: action.project }));
                    this.store.dispatch(addContainerDTO({ containerDTO: action.containerDTO }));
                    this.store.dispatch(
                        loadCustomerShipModeAndDivison({
                            projectId: action.project.id,
                            containerId: action.containerDTO.id,
                            kundenauftragsnummer: action.containerDTO.kundenauftragsnummer,
                        }),
                    );
                    this.store.dispatch(
                        loadKartons({
                            projectId: action.project.id,
                            containerId: action.containerDTO.id,
                            kundenauftragsnummer: action.containerDTO.kundenauftragsnummer,
                        }),
                    );
                    this.store.dispatch(deleteDetailKarton());
                    this.store.dispatch(deleteKartonContent());
                }),
            ),
        { dispatch: false },
    );

    /**
     * läd Customer Ship Mode und Division
     */
    loadCustomerShipModeAndDivison$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadCustomerShipModeAndDivison),
            mergeMap(action =>
                this.packingListService
                    .getCustomerShipModeAndDivision(action.projectId, action.containerId, action.kundenauftragsnummer)
                    .pipe(
                        map(data => {
                            if (data == null) {
                                return setCustomerShipModeAndDivisonSuccessfilly({
                                    shipmode: '',
                                    division: '',
                                });
                            }
                            return setCustomerShipModeAndDivisonSuccessfilly({
                                shipmode: data.customerShipMode,
                                division: data.customerDivision,
                            });
                        }),
                        catchError(() => EMPTY),
                    ),
            ),
        ),
    );

    setCustomerShipModeAndDivision$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setCustomerShipModeAndDivision),
            mergeMap(action =>
                this.packingListService
                    .setCustomerShipModeAndDivision(
                        action.projectId,
                        action.containerId,
                        action.kundenauftragsnummer,
                        action.shipmode,
                        action.division,
                    )
                    .pipe(
                        map(() =>
                            setCustomerShipModeAndDivisonSuccessfilly({
                                shipmode: action.shipmode,
                                division: action.division,
                            }),
                        ),
                        catchError(() => EMPTY), // oder catchError(error => of({ type: 'Error', payload: error }))
                    ),
            ),
        ),
    );

    /**
     * läd die Kartons vom backend
     */
    loadKarton$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadKartons),
            mergeMap(action =>
                this.packingListService.getKartons(action.projectId, action.containerId).pipe(
                    map(data => {
                        return loadKartonsSuccessfully({ kartons: data });
                    }),
                    catchError(() => EMPTY),
                ),
            ),
        ),
    );

    /**
     * läd die Kartons aus dem Store, wenn es dort keine gibt, dann wird "loadKartons" dispatcht
     */
    loadKartonsIfNotExists$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadKartonsIfNotExists),
                concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(selectKartons))))),
                tap(([action, kartons]) => {
                    if (kartons.length === 0) {
                        loadKartons({
                            projectId: action.projectId,
                            containerId: action.containerId,
                            kundenauftragsnummer: action.kundenauftragsnummer,
                        });
                    }
                }),
            ),
        { dispatch: false },
    );

    /**
     * fügt den detailKarton hinzu. Gibt es eine KartonId, dann wird "loadExistingKartonContent" dispatcht
     * ist keine ContainerId vorhanden, dann wird "loadEmptyKartonContent" dispatcht
     */
    addDetailKarton$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(addDetailKarton),
                concatMap(action => {
                    this.store.dispatch(deleteDetailKarton());
                    return of(action).pipe(withLatestFrom(this.store.pipe(select(selectProjectIdAndContainerId))));
                }),
                tap(([action, projectIdAndContainerId]) => {
                    this.store.dispatch(addDetailKartonSuccesfully({ karton: action.karton }));
                    if (action.karton.id) {
                        this.store.dispatch(loadExistingKartonContent({ kartonId: action.karton.id }));
                    } else {
                        this.store.dispatch(
                            loadEmptyKartonContent({
                                projectId: projectIdAndContainerId.projectId,
                                containerId: projectIdAndContainerId.containerId,
                                kunu: projectIdAndContainerId.kundenauftragsnummer,
                            }),
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    /**
     * dupliziert einen selektierten Karton
     */
    duplicateKarton$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(duplicateKarton),
                concatMap(action => {
                    this.store.dispatch(deleteDetailKarton());
                    return of(action).pipe(withLatestFrom(this.store.pipe(select(selectProjectIdAndContainerId))));
                }),
                tap(([action, projectIdAndContainerId]) => {
                    const duplicatedKarton = { ...initialKarton };
                    duplicatedKarton.gewicht = action.karton.gewicht;
                    duplicatedKarton.abmessung = action.karton.abmessung;
                    duplicatedKarton.nummer = action.karton.nummer;

                    this.store.dispatch(addDetailKartonSuccesfully({ karton: duplicatedKarton }));
                    this.store.dispatch(
                        loadEmptyKartonContent({
                            projectId: projectIdAndContainerId.projectId,
                            containerId: projectIdAndContainerId.containerId,
                            kunu: projectIdAndContainerId.kundenauftragsnummer,
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    /**
     * läd einen leeren KartonContent vom backend
     */
    loadEmptyKartonContent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadEmptyKartonContent),
            mergeMap(action =>
                this.packingListService.getEmptyContent(action.projectId, action.containerId, action.kunu).pipe(
                    map(data => loadEmptyKartonContentSuccessfully({ kartonContent: data })),
                    catchError(() => EMPTY),
                ),
            ),
        ),
    );

    /**
     * läd einen existierenden KartonContent vom backend
     */
    loadExistingKartonContent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadExistingKartonContent),
            mergeMap(action =>
                this.packingListService.getExistingContent(action.kartonId).pipe(
                    map(data => loadExistingKartonContentSuccessfully({ kartonContent: data })),
                    catchError(() => EMPTY),
                ),
            ),
        ),
    );

    /**
     * speichert einen Karton, danach wird "loadKartons" dispatch ausgeführt
     */
    saveDetailKarton$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(saveDetailKarton),
                mergeMap(action =>
                    this.packingListService.update(action.kartonUpdateRequest).pipe(
                        map(karton => {
                            if (karton) {
                                this.store.dispatch(
                                    loadKartons({
                                        projectId: action.kartonUpdateRequest.projectId,
                                        containerId: action.kartonUpdateRequest.containerId,
                                        kundenauftragsnummer: action.kartonUpdateRequest.kundenauftragsnummer,
                                    }),
                                );
                                this.store.dispatch(deleteDetailKarton());
                                this.store.dispatch(loadContainerDTO());
                                this.store.dispatch(reloadLieferpositionen());
                            }
                        }),
                    ),
                ),
            ),
        { dispatch: false },
    );

    /**
     * löscht einen Karton, danach wird "loadKartons" dispatch
     */
    deleteKarton$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(deleteKarton),
                mergeMap(action =>
                    this.packingListService.delete(action.kartonId).pipe(
                        mergeMap(deleteAction =>
                            of(action)
                                .pipe(withLatestFrom(this.store.pipe(select(selectProjectIdAndContainerId))))
                                .pipe(
                                    map(([_deleteAction, projectIdAndContainerId]) => {
                                        this.store.dispatch(
                                            loadKartons({
                                                projectId: projectIdAndContainerId.projectId,
                                                containerId: projectIdAndContainerId.containerId,
                                                kundenauftragsnummer: projectIdAndContainerId.kundenauftragsnummer,
                                            }),
                                        );
                                        this.store.dispatch(deleteDetailKarton());
                                        this.store.dispatch(loadContainerDTO());
                                        this.store.dispatch(reloadLieferpositionen());
                                    }),
                                ),
                        ),
                    ),
                ),
            ),
        { dispatch: false },
    );

    deleteSelectedKartons$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(deleteSelectedKartons),
                mergeMap(action =>
                    this.packingListService.deleteWithListOfIds(action.kartonIds).pipe(
                        mergeMap(deleteAction =>
                            of(action)
                                .pipe(withLatestFrom(this.store.pipe(select(selectProjectIdAndContainerId))))
                                .pipe(
                                    map(([_deleteAction, projectIdAndContainerId]) => {
                                        this.store.dispatch(
                                            loadKartons({
                                                projectId: projectIdAndContainerId.projectId,
                                                containerId: projectIdAndContainerId.containerId,
                                                kundenauftragsnummer: projectIdAndContainerId.kundenauftragsnummer,
                                            }),
                                        );
                                        this.store.dispatch(deleteDetailKarton());
                                        this.store.dispatch(loadContainerDTO());
                                        this.store.dispatch(reloadLieferpositionen());
                                    }),
                                ),
                        ),
                    ),
                ),
            ),
        { dispatch: false },
    );

    /**
     * läd die Kartons vom backend
     */
    selectAllKartonsForPrint$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectAllKartonsForPrint),
            mergeMap(action =>
                this.packingListService.getKartons(action.projectId, action.containerId).pipe(
                    map(data => {
                        for (let index = 0; index < data.length; index++) {
                            data[index].cbox = true;
                        }
                        return selectAllKartonsForPrintSuccesfully({ kartons: data });
                    }),
                    catchError(() => EMPTY),
                ),
            ),
        ),
    );
}
