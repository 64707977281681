import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en';
import localeCN from '@angular/common/locales/zh-Hans';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
    constructor(translate: TranslateService) {
        translate.addLangs(['en', 'de', 'cn']);
        translate.setDefaultLang('en');

        const browserLang: string = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de|cn/) ? browserLang : 'de');

        registerLocaleData(localeDE, 'de');
        registerLocaleData(localeEN, 'en');
        registerLocaleData(localeCN, 'cn');
    }
}
