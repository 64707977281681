/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tab-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-drag-scroll";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../../../node_modules/ngx-drag-scroll/ngx-drag-scroll.ngfactory";
import * as i5 from "@angular/common";
import * as i6 from "./tab-list.component";
import * as i7 from "@angular/router";
import * as i8 from "@angular/cdk/overlay";
var styles_TabListComponent = [i0.styles];
var RenderType_TabListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TabListComponent, data: {} });
export { RenderType_TabListComponent as RenderType_TabListComponent };
function View_TabListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "tab-hover"]], null, [[null, "contextmenu"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("contextmenu" === en)) {
        _co.openContextMenu($event, _v.context.$implicit);
        var pd_0 = ($event.preventDefault() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["drag-scroll-item", ""]], [[8, "className", 0], [4, "display", null]], null, null, null, null)), i1.ɵdid(2, 16384, [[3, 4]], 0, i2.DragScrollItemDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "span", [["class", "pointer mr-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSelectClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "font-weight-normal"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRemoveClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "icon-close icons"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "tab d-flex align-items-center font-weight-light ", ((_co.activeTab && (_v.context.$implicit.url === _co.activeTab.url)) ? "active" : ""), ""); var currVal_1 = i1.ɵnov(_v, 2).display; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_v.context.$implicit.nameVisible)); _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.$implicit.suffix; _ck(_v, 7, 0, currVal_3); }); }
function View_TabListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "d-flex align-items-center w-100 tabFixed"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "h-100 pointer"], ["id", "left-arrow"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLeftClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "icon-arrow-left icons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "drag-scroll", [["class", "d-flex container bb"], ["id", "tabs-container"]], [[4, "pointer-events", null]], [[null, "wheel"], ["window", "resize"]], function (_v, en, $event) { var ad = true; if (("wheel" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onWheel($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:resize" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onWindowResize() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_DragScrollComponent_0, i4.RenderType_DragScrollComponent)), i1.ɵdid(4, 13287424, [[1, 4], ["container", 4]], 1, i2.DragScrollComponent, [i1.ElementRef, i1.Renderer2, i5.DOCUMENT], null, null), i1.ɵqud(603979776, 3, { _children: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_TabListComponent_2)), i1.ɵdid(7, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "h-100 pointer"], ["id", "right-arrow"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRightClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "icon-arrow-right icons"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.tabs; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4)._pointerEvents; _ck(_v, 3, 0, currVal_0); }); }
function View_TabListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "section", [["class", "context-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRemoveClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRemoveAllOtherClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRemoveAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("btnLabel.close")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("tab.closeAllOtherTabs")); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("tab.closeAllTabs")); _ck(_v, 8, 0, currVal_2); }); }
export function View_TabListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { ds: 0 }), i1.ɵqud(402653184, 2, { contextMenu: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabListComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [[2, 2], ["contextMenu", 2]], null, 0, null, View_TabListComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.tabs && (_co.tabs.length > 0)); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_TabListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "app-tabs-list", [], null, null, null, View_TabListComponent_0, RenderType_TabListComponent)), i1.ɵdid(1, 49152, null, 0, i6.TabListComponent, [i7.Router, i8.Overlay, i1.ViewContainerRef], null, null)], null, null); }
var TabListComponentNgFactory = i1.ɵccf("app-tabs-list", i6.TabListComponent, View_TabListComponent_Host_0, { tabs: "tabs", activeTab: "activeTab" }, { onSelect: "onSelect", onRemove: "onRemove" }, []);
export { TabListComponentNgFactory as TabListComponentNgFactory };
