<div *ngIf="tabs && tabs.length > 0" class="d-flex align-items-center w-100 tabFixed">
    <div id="left-arrow" class="h-100 pointer" (click)="onLeftClick()"><i class="icon-arrow-left icons"></i></div>
    <drag-scroll #container id="tabs-container" class="d-flex container bb">
        <div
            *ngFor="let tab of tabs"
            class="tab-hover"
            (contextmenu)="openContextMenu($event, tab); $event.preventDefault()"
        >
            <div
                drag-scroll-item
                class="tab d-flex align-items-center font-weight-light {{
                    activeTab && tab.url === activeTab.url ? 'active' : ''
                }}"
            >
                <span class="pointer mr-2" (click)="onSelectClick(tab)">
                    {{ tab.nameVisible | translate }} <span class="font-weight-normal">{{ tab.suffix }}</span>
                </span>
                <span class="pointer" (click)="onRemoveClick(tab)">
                    <i class="icon-close icons"></i>
                </span>
            </div>
        </div>
    </drag-scroll>
    <div id="right-arrow" class="h-100 pointer" (click)="onRightClick()"><i class="icon-arrow-right icons"></i></div>
</div>

<ng-template #contextMenu let-tab>
    <section class="context-menu">
        <div (click)="onRemoveClick(tab)">{{ 'btnLabel.close' | translate }}</div>
        <div (click)="onRemoveAllOtherClick(tab)">{{ 'tab.closeAllOtherTabs' | translate }}</div>
        <div (click)="onRemoveAll()">{{ 'tab.closeAllTabs' | translate }}</div>
    </section>
</ng-template>
