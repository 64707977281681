import { Directive, Input, ElementRef, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit {

    @Input()
    set hasPermission(permission: string) {
        this.permission = permission;
        this.updateView();
    }
    public permission: any;

    constructor(private element: ElementRef,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private autService: AuthService) {
    }

    ngOnInit() {
    }

    private updateView() {
        if (this.checkPermission()) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }

    private checkPermission() {
        return this.autService.hasPermissions(this.permission);
    }

    private checkString(a: string, b: string): boolean {
        return a === b ? true : false;
    }
}
