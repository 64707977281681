<div class="app" dir="ltr" [ngClass]="themeSkinColor" [class.sidebar-closed]="sidebarClosed">
    <ng-sidebar-container>
        <div ng-sidebar-content>
            <ng-sidebar
                #sidenav
                [(opened)]="_opened"
                [(mode)]="_mode"
                [(closeOnClickOutside)]="_closeOnClickOutside"
                [(showBackdrop)]="_showBackdrop"
            >
                <div class="sidebar-panel chankya-sidenav">
                    <div class="sidebar-logo-wrap">
                        <div
                            class="logo-text"
                            style="color: white;     color: white;
                        background-color: #061C29;
                        margin: 0;
                        padding: 0;
                        justify-content: center;
                        display: flex;
                        padding: 10px 0;"
                        >
                            <span width="121" height="16">
                                <i>fashion42 <b class="text-danger env-buildSystem"> </b></i>
                            </span>
                        </div>
                        <div class="sidebar-logo d-flex">
                            <!-- <div class="logo-sign">
                                <img src="assets/img/smoking.png" alt="" />
                            </div> -->
                        </div>
                    </div>

                    <div class="sidebar-container ps-container ps-theme-default ps-active-y">
                        <div class="user-section text-center">
                            <!-- <div class="sidebar-user-wrap"> -->
                            <div class="sidebar-user-inner">
                                <div class="sidebar-user-info-wrap">
                                    <div class="sidebar-user-name">{{user?.fullname}}</div>
                                </div>
                            </div>
                            <!-- </div> -->
                        </div>
                    </div>

                    <div class="sidebar-container ps-container ps-theme-default ps-active-y">
                        <div class="sidenav-scrollbar-container">
                            <perfect-scrollbar class="ps-container ps-theme-default">
                                <nav menuToggleDirective class="navigation">
                                    <ul class="chankya-nav">
                                        <li class="nav-item" menuToggleLink *ngFor="let menuitem of menuItems.getAll()">
                                            <div *hasPermission="menuitem.permission">
                                                <a
                                                    target="_blank"
                                                    href="{{menuitem.state}}"
                                                    *ngIf="menuitem.type === 'ext-link'"
                                                    tourAnchor="tour-ui"
                                                >
                                                    <i class="{{ menuitem.icon }}"></i>
                                                    <p class="text-nowrap mb-0">
                                                        <span>{{ menuitem.name | translate }}</span>
                                                    </p>
                                                </a>
                                            </div>
                                            <div *hasPermission="menuitem.permission">
                                                <a
                                                    menuToggle
                                                    [routerLink]="['/', menuitem.state]"
                                                    routerLinkActive="active-link"
                                                    *ngIf="menuitem.type === 'link'"
                                                >
                                                    <i class="{{ menuitem.icon }}"></i>
                                                    <p class="text-nowrap mb-0">
                                                        <span>{{ menuitem.name | translate }}</span>
                                                    </p>
                                                </a>
                                            </div>
                                            <div *hasPermission="menuitem.permission">
                                                <a menuToggle href="javascript:void(0)" *ngIf="menuitem.type === 'sub'">
                                                    <i class="{{ menuitem.icon }}"></i>
                                                    <p class="text-nowrap mb-0">
                                                        <span class="{{menuitem.state}}-btn"
                                                            >{{ menuitem.name | translate }}</span
                                                        >
                                                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                                                    </p>
                                                </a>
                                            </div>
                                            <ul class="sub-menu chankya-nav" *ngIf="menuitem.type === 'sub'">
                                                <li
                                                    class="nav-item"
                                                    *ngFor="let childitem of menuitem.children"
                                                    routerLinkActive="open"
                                                >
                                                    <div *hasPermission="childitem.permission">
                                                        <a
                                                            [routerLink]="['/', menuitem.state, childitem.state ]"
                                                            routerLinkActive="active-link"
                                                        >
                                                            <i class="icon icon-arrows-right-double"></i>
                                                            <p class="text-nowrap mb-0">
                                                                <span>{{ childitem.name | translate }}</span>
                                                            </p>
                                                        </a>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            </perfect-scrollbar>
                        </div>
                    </div>
                </div>
                <!-- sidebar panel closed -->
            </ng-sidebar>

            <section class="main-contents">
                <header class="main-header">
                    <div class="d-flex justify-content-between align-items-center chankya-header-toolbar w-100">
                        <div class="h-100 d-flex align-items-center col-sm-6 col-md-6 col-lg-6 pl-0 pr-0">
                            <div class="h-100 d-flex justify-content-start align-items-center">
                                <div class="h-100 chankya-ham-icon">
                                    <button
                                        class="c-hamburger c-hamburger--rot"
                                        (click)="sidebarClosedFunction()"
                                        (click)="_toggleOpened()"
                                    >
                                        <span>{{'dashboard.toggleMenu' | translate}}</span>
                                    </button>
                                </div>
                            </div>
                            <!-- die Sucheingabe vorerst ausgeblendet, bis eine Suche programmiert wurde -->
                            <!-- <div class="h-100 pl-4 search-bar w-100 h-100 d-none-sm search-w">
                <form class="h-100 search-form">
                  <div class="h-100 search-i">
                    <input class="form-control" type="text" [placeholder]="'dashboard.search' | translate" data-toggle="modal"  />
                  </div>
                </form>
              </div> -->
                        </div>

                        <div class="align-items-end pr-0 col-sm-6 col-md-6 col-lg-6">
                            <div class="d-flex secondary-menu justify-content-end align-items-center">
                                <ul
                                    class="nav secondary-menu-list d-flex justify-content-end align-items-center"
                                    data-dropdown-in="fadeIn"
                                    data-dropdown-out="fadeOut"
                                >
                                    <li class="nav-item p-0">
                                        <ng-select
                                            class="country-select"
                                            [items]="translate.getLangs()"
                                            [clearable]="false"
                                            [(ngModel)]="currentLang"
                                            (change)="onLangChange(currentLang)"
                                        >
                                            <ng-template ng-label-tmp let-item="item">
                                                <img [src]="'assets/img/langs/' + item + '.png'" /> {{item}}
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                                <img [src]="'assets/img/langs/' + item + '.png'" /> {{item}}
                                            </ng-template>
                                        </ng-select>
                                    </li>

                                    <!--fullscrean-->
                                    <li
                                        tourAnchor="tour-full-screen"
                                        class="d-none-sm fullscreen-toggle"
                                        (click)="toggleFullscreen()"
                                    >
                                        <a href="javascript:void(0)" class="zoom-out">
                                            <i class="material-icons" *ngIf="!isFullscreen">tv</i>
                                            <i class="material-icons" *ngIf="isFullscreen">fullscreen_exit</i>
                                        </a>
                                    </li>

                                    <!--notifications-->
                                    <li class="d-none-sm dropdown">
                                        <app-notification></app-notification>
                                    </li>

                                    <!--LOGOUT-->
                                    <li class="dropdown">
                                        <a
                                            class="relative rounded-bg"
                                            href="javascript:void(0)"
                                            id="navbarDropdownMenuLin"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <img
                                                class="rounded-circle"
                                                src="assets/img/default-user.png"
                                                alt="User-image"
                                                width="43"
                                                height="43"
                                            />
                                            <div class="online-caret bg-success circle">
                                                <i class="fa fa-caret-down"></i>
                                            </div>
                                        </a>
                                        <div
                                            class="dropdown-menu rounded-0 dropdown-menu-right p-0"
                                            aria-labelledby="navbarDropdownMenuLin"
                                        >
                                            <button class="dropdown-item d-flex pb-2" (click)="onLogout()">
                                                <div class="icon-area align-self-start">
                                                    <i class="fa fa-power-off"></i>
                                                </div>
                                                <div class="icon-msg ml-2 mr-2">
                                                    <span class="align-self-start d-block ">
                                                        {{'dashboard.logout' | translate }}</span
                                                    >
                                                </div>
                                            </button>

                                            <a [routerLink]="['/todos']" class="dropdown-item  d-flex pb-2">
                                                <div class="icon-area align-self-start">
                                                    <i class="fa fa-list-ul"></i>
                                                </div>
                                                <div class="icon-msg ml-2 mr-2">
                                                    <span class="align-self-start d-block ">
                                                        {{ 'general.todos' | translate }}
                                                    </span>
                                                </div>
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </header>
                <!-- main header closed -->

                <!--MAIN CONTAINER-->
                <div class="chankya-base-container pos-relative" #scrollContainer>
                    <app-tabs></app-tabs>
                    <div class="d-flex justify-content-between align-items-center page-info w-100 d-none-sm">
                        <a class="navbar-brand" href="javascript:void(0)">{{header}}</a>
                        <!--<breadcrumb></breadcrumb>-->
                    </div>
                    <div class="inner-container">
                        <router-outlet (activate)="onActivate($event, scrollContainer)"></router-outlet>
                    </div>
                </div>
                <!-- chankya base container closed -->
            </section>
            <!-- main content closed -->
        </div>
        <!-- Sidebar Closed -->
    </ng-sidebar-container>
</div>
<!-- app closed -->
