import { TableStoreModel } from '../table/table-store-model';

export const ADD_TABLE = 'ADD_TABLE';
export const REMOVE_TABLE = 'REMOVE_TABLE';

export function tableReducer(allTables: TableStoreModel[], action) {
    switch (action.type) {
        case ADD_TABLE:
        const index = allTables.map(table => table.url).indexOf(action.payload.url);
        if (index === -1) {
          return [...allTables, action.payload];
        } else {
          const newTables = [...allTables];
          newTables[index] = action.payload;
          return newTables;
        }
      case REMOVE_TABLE:
        return allTables.filter(table => {
          return table.url !== action.payload.url;
        });
      default:
        return allTables;
    }
}
