import { Injectable } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import * as autoScroll from 'dom-autoscroller';


@Injectable()
export class ScrollbarService {

    constructor() {
    }

    buildScrollbarById(id, options) {
        const container = <HTMLElement>document.querySelector('#' + id);
        if (container) {
            const ps = new PerfectScrollbar(container, options);
            ps.update();

            autoScroll([
                document.querySelector('#' + id),
            ], {
                    margin: 20,
                    maxSpeed: 5,
                    scrollWhenOutside: false,
                    autoScroll: function () {
                        // Only scroll when the pointer is down.
                        return this.down;
                        // return true;
                    }
                });
        }

    }

    buildScrollbarByClass(className, options) {
        const container = <HTMLElement>document.querySelector('.' + className);
        if (container) {
            const ps = new PerfectScrollbar(container, options);
            ps.update();
        }
    }
}
