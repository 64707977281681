import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@auth/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../auth/auth.service";
export class ErrorMessageService {
    constructor(toastr, translate, authService) {
        this.toastr = toastr;
        this.translate = translate;
        this.authService = authService;
    }
    handleError(error) {
        if (error.status === 401) {
            // session is expired
            this.toastr.clear();
            this.authService.expiredSession();
        }
        return Promise.reject(error);
    }
    showErrorMsg(key) {
        const title = this.translate.instant('notifications.error');
        const msg = this.translate.instant(key);
        this.toastr.error(msg, title);
    }
    showWarningMsg(key) {
        const title = this.translate.instant('notifications.warn');
        const msg = this.translate.instant(key);
        this.toastr.warning(msg, title);
    }
}
ErrorMessageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorMessageService_Factory() { return new ErrorMessageService(i0.ɵɵinject(i1.ToastrService), i0.ɵɵinject(i2.TranslateService), i0.ɵɵinject(i3.AuthService)); }, token: ErrorMessageService, providedIn: "root" });
