<!-- <input type="checkbox" [(ngModel)]="params.value" (change)="this.refresh(this.params)" /> -->

<span class="actionred" *ngIf="checkBox">
    <!-- <a class="material-icons" (click)="onCheckBoxClick($event)">delete</a> -->
    <!-- <input type="checkbox" (click)="onCheckBoxClick($event)" /> -->
    <input
        type="checkbox"
        [(ngModel)]="params.data.cbox"
        (click)="onCheckBoxClick($event)"
        (change)="this.refresh(this.params)"
    />
</span>
