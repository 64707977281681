import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app-state';
import { Project } from '@project/models/project';
import { ProjectStoreModel } from './project-store-model';
import * as ProjectReducer from '../reducers/project-reducer';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ProjectStoreService {
    private project$$: BehaviorSubject<Project> = new BehaviorSubject<Project>(null);
    project$: Observable<Project> = this.project$$.asObservable();

    constructor(private store: Store<AppState>) {}

    private send(project: Project): void {
        this.project$$.next(project);
    }

    addProject(id: number, project: Project, urlString: string) {
        const projectStoreModel: ProjectStoreModel = {
            id: id,
            url: urlString,
            project: project,
            hasChangesWithoutSaving: false,
        };
        this.dispatchProjectStoreModel(projectStoreModel, ProjectReducer.ADD_PROJECT);
        this.send(project);
    }

    removeProjectByUrl(urlString: string) {
        const projectStoreModel: ProjectStoreModel = {
            id: null,
            url: urlString,
            project: null,
            hasChangesWithoutSaving: null,
        };
        this.dispatchProjectStoreModel(projectStoreModel, ProjectReducer.REMOVE_PROJECT);
    }

    updateProject(project: Project) {
        const projectStoreModel: ProjectStoreModel = {
            id: project.id,
            url: null,
            project: project,
            hasChangesWithoutSaving: true,
        };
        this.dispatchProjectStoreModel(projectStoreModel, ProjectReducer.UPDATE_PROJECT);
        this.send(project);
    }

    saveProject(project: Project) {
        const projectStoreModel: ProjectStoreModel = {
            id: project.id,
            url: null,
            project: project,
            hasChangesWithoutSaving: false,
        };
        this.dispatchProjectStoreModel(projectStoreModel, ProjectReducer.SAVE_PROJECT);
        this.loadProject(project);
    }

    loadProject(project: Project) {
        const projectStoreModel: ProjectStoreModel = {
            id: project.id,
            url: null,
            project: null,
            hasChangesWithoutSaving: null,
        };
        this.dispatchProjectStoreModel(projectStoreModel, ProjectReducer.LOAD_PROJECT);
        this.send(project);
    }

    setProjectToSaved(project: Project) {
        const projectStoreModel: ProjectStoreModel = {
            id: project.id,
            url: null,
            project: project,
            hasChangesWithoutSaving: false,
        };
        this.dispatchProjectStoreModel(projectStoreModel, ProjectReducer.REMOVE_HASCHANGESWITHOUTSAVINGS);
        this.send(project);
    }

    clearAllProjects() {
        this.store.dispatch({
            type: ProjectReducer.CLEAR_ALL_PROJECT,
        });
    }

    private dispatchProjectStoreModel(projectStoreModel: ProjectStoreModel, type: string) {
        this.store.dispatch({
            type: type,
            payload: projectStoreModel,
        });
    }

    findIndexOfId(projects: ProjectStoreModel[], id: number): number {
        return projects
            ? projects
                  .filter(project => !!project)
                  .map(project => project.id)
                  .indexOf(id)
            : null;
    }

    findProjectStoreModelWithId(projects: ProjectStoreModel[], id: number): ProjectStoreModel {
        return projects ? projects.find(projectStoreModel => projectStoreModel.id === id) : null;
    }
}
