import { BaseService } from '@core/service/base.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@auth/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { catchError, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../auth/auth.service";
import * as i4 from "@angular/common/http";
export class ShippingPositionService extends BaseService {
    constructor(toastr, translate, authService, httpClient) {
        super(toastr, translate, authService);
        this.toastr = toastr;
        this.translate = translate;
        this.authService = authService;
        this.httpClient = httpClient;
        this.url = `${environment.apiBaseUrl}/shippingPosition`;
    }
    /*
    CREATE
     */
    createShippingPosition(createShippingPositionRequest) {
        return this.httpClient
            .post(this.url + `/`, createShippingPositionRequest, {
            withCredentials: true,
        })
            .pipe(catchError(err => {
            this.showErrorMsg('generic.problems.update');
            return this.handleError(err);
        }));
    }
    /*
    READ ALL
    */
    getLieferpositionDTOs(shippingPositionRequest) {
        const params = new HttpParams().append('purchaseOrderPositions', shippingPositionRequest.toString());
        return this.httpClient
            .get(this.url, {
            withCredentials: true,
            params: params,
        })
            .pipe(catchError(err => {
            this.showErrorMsg('generic.problems.downloadAll');
            return this.handleError(err);
        }));
    }
    /*
    READ SINGLE
    */
    getSizeQuantities(lieferpositionId) {
        return this.httpClient
            .get(this.url + `/` + lieferpositionId + `/sizeQuantities`, { withCredentials: true })
            .pipe(catchError(err => {
            this.showErrorMsg('generic.problems.downloadAll');
            return this.handleError(err);
        }));
    }
    /*
    SAVE
    */
    saveSizeQuantities(updateGroessenMengenRequest) {
        return this.httpClient
            .post(this.url + `/` + updateGroessenMengenRequest.id + `/sizeQuantities`, updateGroessenMengenRequest, { withCredentials: true })
            .pipe(map(data => {
            this.showSuccessMsg('generic.success.update');
            return data;
        }), catchError(err => {
            this.showErrorMsg('generic.problems.update');
            return this.handleError(err);
        }));
    }
    saveShippingPosition(updateShippingPosition) {
        return this.httpClient
            .post(this.url + `/` + updateShippingPosition.id, updateShippingPosition, {
            withCredentials: true,
        })
            .pipe(map(data => {
            this.showSuccessMsg('generic.success.update');
            return data;
        }), catchError(err => {
            this.showErrorMsg('generic.problems.update');
            return this.handleError(err);
        }));
    }
    /*
    DELETE
    */
    deleteLieferposition(id) {
        return this.httpClient
            .delete(this.url + `/` + id, {
            withCredentials: true,
        })
            .pipe(catchError(err => {
            this.showErrorMsg('generic.problems.remove');
            return this.handleError(err);
        }));
    }
}
ShippingPositionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ShippingPositionService_Factory() { return new ShippingPositionService(i0.ɵɵinject(i1.ToastrService), i0.ɵɵinject(i2.TranslateService), i0.ɵɵinject(i3.AuthService), i0.ɵɵinject(i4.HttpClient)); }, token: ShippingPositionService, providedIn: "root" });
