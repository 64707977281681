import { Injectable, ɵEMPTY_ARRAY } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Karton } from '@shipping/models/karton';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { KartonContent } from '@shipping/models/karton-content';
import { KartonUpdateRequest } from '@shipping/models/karton-update-request';
import { KartonDTO } from '@shipping/models/karton-dto';

@Injectable({
    providedIn: 'root',
})
export class PackingListService {
    constructor(
        private httpClient: HttpClient,
        protected toastr: ToastrService,
        protected translate: TranslateService,
    ) {}

    /**
     * GET all container
     */
    getKartons(projectId: number, containerId: number, kundenauftragsnummer?: string): Observable<KartonDTO[]> {
        const params = new HttpParams().append('project', '' + projectId).append('container', '' + containerId);

        if (kundenauftragsnummer) {
            params.append('customerodernumber', kundenauftragsnummer);
        }

        return this.httpClient
            .get<KartonDTO[]>(`${environment.apiBaseUrl}/karton`, {
                withCredentials: true,
                params: params,
            })
            .pipe(
                catchError(err => {
                    const msg = this.translate.instant('generic.problems.downloadAll');
                    const title = this.translate.instant('notifications.error');
                    this.toastr.error(msg, title);
                    return of(ɵEMPTY_ARRAY);
                }),
            );
    }

    /**
     * GET empty Content
     */
    getEmptyContent(projectId: number, containerId: number, kunu: string): Observable<KartonContent> {
        const params = new HttpParams()
            .append('project', '' + projectId)
            .append('container', '' + containerId)
            .append('kunu', kunu);

        return this.httpClient
            .get<any>(`${environment.apiBaseUrl}/karton/content`, {
                withCredentials: true,
                params: params,
            })
            .pipe(
                catchError(err => {
                    const msg = this.translate.instant('generic.problems.downloadAll');
                    const title = this.translate.instant('notifications.error');
                    this.toastr.error(msg, title);
                    return of(ɵEMPTY_ARRAY);
                }),
            );
    }

    /**
     * GET existing Content
     */
    getExistingContent(kartonId: number): Observable<KartonContent> {
        const params = new HttpParams().append('karton', '' + kartonId);
        return this.httpClient
            .get<any>(`${environment.apiBaseUrl}/karton/content`, {
                withCredentials: true,
                params: params,
            })
            .pipe(
                catchError(err => {
                    const msg = this.translate.instant('generic.problems.downloadAll');
                    const title = this.translate.instant('notifications.error');
                    this.toastr.error(msg, title);
                    return of(ɵEMPTY_ARRAY);
                }),
            );
    }

    /**
     * POST update container
     */
    update(request: KartonUpdateRequest): Observable<any> {
        return this.httpClient
            .post<any>(`${environment.apiBaseUrl}/karton`, request, {
                withCredentials: true,
            })
            .pipe(
                catchError(err => {
                    const msg = this.translate.instant('generic.problems.downloadAll');
                    const title = this.translate.instant('notifications.error');
                    this.toastr.error(msg, title);
                    return of(ɵEMPTY_ARRAY);
                }),
            );
    }

    /*
    DELETE
    */
    delete(id: number): Observable<any> {
        return this.httpClient
            .delete<any>(`${environment.apiBaseUrl}/karton/` + id, {
                withCredentials: true,
            })
            .pipe(
                catchError(err => {
                    const msg = this.translate.instant('generic.problems.remove');
                    const title = this.translate.instant('notifications.error');
                    this.toastr.error(msg, title);
                    return of(ɵEMPTY_ARRAY);
                }),
            );
    }

    /**
     * Delete By a List Of Ids
     */
    deleteWithListOfIds(listOfKartonIds: number[]): Observable<any> {
        return this.httpClient
            .request('delete', `${environment.apiBaseUrl}/karton/deleteByIds`, {
                body: listOfKartonIds,
                withCredentials: true,
            })
            .pipe(
                catchError(err => {
                    const msg = this.translate.instant('generic.problems.remove');
                    const title = this.translate.instant('notifications.error');
                    this.toastr.error(msg, title);
                    return of(ɵEMPTY_ARRAY);
                }),
            );
    }

    /**
     * get Customer Shipmode and Division
     */
    getCustomerShipModeAndDivision(
        projectId: number,
        containerId: number,
        kundenauftragsnummer: string,
    ): Observable<any> {
        const params = new HttpParams()
            .append('project', projectId.toString())
            .append('container', containerId.toString())
            .append('kundenauftragsnummer', kundenauftragsnummer);
        return this.httpClient
            .get<any>(`${environment.apiBaseUrl}/karton/costomerShipModeDivision`, {
                withCredentials: true,
                params: params,
            })
            .pipe(
                catchError(err => {
                    const msg = this.translate.instant('generic.problems.downloadAll');
                    const title = this.translate.instant('notifications.error');
                    this.toastr.error(msg, title);
                    return of(ɵEMPTY_ARRAY);
                }),
            );
    }

    setCustomerShipModeAndDivision(
        projectId: number,
        containerId: number,
        kundenauftragsnummer: string,
        shipmode: string,
        division: string,
    ): Observable<any> {
        const params = new HttpParams()
            .append('project', projectId.toString())
            .append('container', containerId.toString())
            .append('kundenauftragsnummer', kundenauftragsnummer)
            .append('shipmode', shipmode)
            .append('division', division);

        return this.httpClient
            .post<any>(`${environment.apiBaseUrl}/karton/costomerShipModeDivision/shipmodeanddivision`, null, {
                withCredentials: true,
                params: params,
            })
            .pipe(
                catchError(err => {
                    const msg = this.translate.instant('generic.problems.downloadAll');
                    const title = this.translate.instant('notifications.error');
                    this.toastr.error(msg, title);
                    return of(ɵEMPTY_ARRAY);
                }),
            );
    }
}
