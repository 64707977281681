import { Action, createReducer, on } from '@ngrx/store';
import { CalculationSearchRequest } from '@app/calculation/models/calculation-search-request';
import * as calculationActions from '../actions/calculation.actions';
import { CalculationProjectPositions } from '@app/calculation/models/calcilation-project-position';

export const calculationFeatureKey = 'calculation';

export interface CalculationState {
    searchRequest: CalculationSearchRequest;
    projectPositions: CalculationProjectPositions[];
    selectedPosition: number;
}

export const initialState: CalculationState = {
    searchRequest: null,
    projectPositions: null,
    selectedPosition: null,
};

const _calulcationReduce = createReducer(
    initialState,

    /**
     * searchRequest
     */
    on(calculationActions.addSearchRequest, (state, { searchRequest }) => ({
        ...state,
        searchRequest: searchRequest,
    })),

    /**
     * Project Caluclaitons Response
     */
    on(calculationActions.addProjectPositions, (state, { projectPositions }) => ({
        ...state,
        projectPositions: projectPositions,
    })),
    on(calculationActions.setSelectedPosition, (state, { selectedPosition }) => ({
        ...state,
        selectedPosition: selectedPosition,
    })),

    /**
     * Clear
     */
    on(calculationActions.clearCalculation, (state, {}) => ({
        ...initialState,
    })),
);

export function calculationReducer(state = initialState, action: Action): CalculationState {
    return _calulcationReduce(state, action);
}
