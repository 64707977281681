import { createAction, props } from '@ngrx/store';
import { BestellpositionRequest } from '@shipping/models/bestellposition-request';
import { LieferpositionDto } from '@shipping/models/lieferposition-dto';
import { SizeQuantity } from '@shipping/models/size-quantity';
import { LieferpositionRequest } from '@shipping/models/lieferposition-request';
import { BestellpositionResponse, ContainerDTO } from '@shipping/models/bestellposition-response';
import { BestellpositionDTO } from '@shipping/models/bestellposition-dto';

/**
 * searchRequest
 */
export const addSearchRequest = createAction(
    '[ShippingDialog] AddSearchRequest',
    props<{ searchRequest: BestellpositionRequest }>(),
);

/**
 * lieferpositionRequest
 */
export const addLieferpositionRequest = createAction(
    '[ShippingDialog] AddLieferpositionRequest',
    props<{ lieferpositionRequest: LieferpositionRequest }>(),
);
export const deleteLieferpositionRequest = createAction('[ShippingDialog] DeleteLieferpositionRequest');

/**
 * bestellpositionResponse
 */
export const addBestellpositionen = createAction(
    '[ShippingDialog] AddBestellpositionen',
    props<{ bestellpositionResponse: BestellpositionResponse }>(),
);
export const updateBestellposition = createAction(
    '[ShippingDialog] Update Bestellposition',
    props<{ bestellpositionDto: BestellpositionDTO }>(),
);
export const updateContainerInBestellpositionResponse = createAction(
    '[ShippingDialog] Update Container in BestelpositionResponse',
    props<{ containerDTO: ContainerDTO[] }>(),
);
export const loadContainerDTO = createAction('[ShippingDialog] Load ContainerDTO');
export const loadContainerDTOsuccessfully = createAction(
    '[ShippingDialog] Load ContainerDTO successfully',
    props<{ containerDTOs: ContainerDTO[] }>(),
);

/**
 * lieferpositionen
 */
export const addLieferpositionen = createAction(
    '[ShippingDialog] AddLieferpositionen',
    props<{ lieferpositionen: LieferpositionDto[] }>(),
);
export const deleteLieferpositionen = createAction('[ShippingDialog] DeleteLieferpositionen');
export const reloadLieferpositionen = createAction('[ShippingDialog] Reload Lieferpositionen');

/**
 * sizeQuantities
 */
export const addSizeQuantities = createAction(
    '[ShippingDialog] AddSizeQuantities',
    props<{ sizeQuantites: SizeQuantity[] }>(),
);
export const deleteSizeQuantities = createAction('[ShippingDialog] DeleteSizeQuantities');

/**
 * url
 */
export const addUrl = createAction('[ShippingDialog] AddUrl', props<{ url: string }>());

/**
 * Sonstiges
 */
export const deleteByurl = createAction('[ShippingDialog] Delete ShippingDialog', props<{ url: string }>());
