/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./tab-list/tab-list.component.ngfactory";
import * as i2 from "./tab-list/tab-list.component";
import * as i3 from "@angular/router";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "@angular/common";
import * as i6 from "./tab.component";
import * as i7 from "@ngrx/store";
import * as i8 from "./tab-store.service";
var styles_TabComponent = [];
var RenderType_TabComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TabComponent, data: {} });
export { RenderType_TabComponent as RenderType_TabComponent };
export function View_TabComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 3, "app-tabs-list", [], null, [[null, "onSelect"], [null, "onRemove"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSelect" === en)) {
        var pd_0 = (_co.onSelect($event) !== false);
        ad = (pd_0 && ad);
    } if (("onRemove" === en)) {
        var pd_1 = (_co.onRemove($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_TabListComponent_0, i1.RenderType_TabListComponent)), i0.ɵdid(1, 49152, null, 0, i2.TabListComponent, [i3.Router, i4.Overlay, i0.ViewContainerRef], { tabs: [0, "tabs"], activeTab: [1, "activeTab"] }, { onSelect: "onSelect", onRemove: "onRemove" }), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.tabs)); var currVal_1 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 3).transform(_co.activeTab)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_TabComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-tabs", [], null, null, null, View_TabComponent_0, RenderType_TabComponent)), i0.ɵdid(1, 114688, null, 0, i6.TabComponent, [i7.Store, i8.TabStoreService, i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TabComponentNgFactory = i0.ɵccf("app-tabs", i6.TabComponent, View_TabComponent_Host_0, {}, {}, []);
export { TabComponentNgFactory as TabComponentNgFactory };
