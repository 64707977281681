import { Action, createReducer, on } from '@ngrx/store';
import * as F42NgrxTableActions from '../actions/f42-ngrx-table.actions';

export const f42NgrxTableFeatureKey = 'f42NgrxTables';

export interface F42NgxTableState {
    url: string;
    tableGroupValue: any;
    filter: any;
    scrollPos: any;
}

export const initialState: State = {
    f42NgxTables: [] = [],
};

export interface State {
    f42NgxTables: F42NgxTableState[];
}

const f42NgrxTableReducer = createReducer(
    initialState,
    on(F42NgrxTableActions.loadTableWithUrl, state => ({ ...state })),
    on(F42NgrxTableActions.resetTable, state => ({
        f42NgxTables: [],
    })),
    on(F42NgrxTableActions.setTable, (state, { f42NgxTables }) => ({
        f42NgxTables: addTable(state, f42NgxTables),
    }))
);

function addTable(state: State, f42NgrxTable) {
    const index = state.f42NgxTables.findIndex(s => s.url === f42NgrxTable.url);

    if (index !== -1) {
        state.f42NgxTables[index] = f42NgrxTable;
    } else {
        state.f42NgxTables.push(f42NgrxTable);
    }
    return state.f42NgxTables;
}

export function reducer(state: State | undefined, action: Action) {
    return f42NgrxTableReducer(state, action);
}

// export function reducer(state = initialState, action: Action): F42NgxTableState {
//   switch (action.type) {

//     default:
//       return state;
//   }
// }
