export interface Karton {
    id: number;
    nummer: number;
    gewicht: string;
    abmessung: string;
    nve: string;
    createdAt: Date;
    createdBy: string;
    lieferpositionIds: number[];
}

export const initialKarton: Karton = {
    id: null,
    nummer: null,
    gewicht: null,
    abmessung: null,
    nve: null,
    createdAt: null,
    createdBy: null,
    lieferpositionIds: [],
};
