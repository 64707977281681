import { Tab } from './tab/tab';
import { tabReducer } from './reducers/tab.reducer';
import { activeTabReducer } from './reducers/active-tab.reducer';
import { FormStoreModel } from './form/form-store-model';
import { formReducer } from './reducers/form.reducer';
import { tableReducer } from './reducers/table.reducer';
import { TableStoreModel } from './table/table-store-model';
import { ProjectStoreModel } from './project/project-store-model';
import { projectReducer } from './reducers/project-reducer';
import { containerReducer } from './reducers/container.reducer';
import { calculationReducer, calculationFeatureKey, CalculationState } from './reducers/calculation.reducer';
import { shippingDialogReducer, ShippingdialogState } from './reducers/shipping-dialog.reducer';
import { packinglistReducer, PackinglistState, packinglistFeatureKey } from './reducers/packinglist.reducer';
import { f42NgrxTableFeatureKey, reducer as f42NgrxTableReducer } from './reducers/f42-ngrx-table.reducer';

export interface AppState {
    activeTab: Tab;
    projects: ProjectStoreModel[];
    tabs: Tab[];
    forms: FormStoreModel[];
    tables: TableStoreModel[];
    shippingDialog: ShippingdialogState;
    packinglist: PackinglistState;
    calculation: CalculationState;
}

export const INITIAL_STATE = {
    activeTab: {
        name: '',
        url: '',
    },
    projects: [],
    tabs: [],
    forms: [],
    tables: [],
};

export const REDUCERS = {
    activeTab: activeTabReducer,
    projects: projectReducer,
    tabs: tabReducer,
    forms: formReducer,
    tables: tableReducer,
    f42NgrxTables: f42NgrxTableReducer,
    container: containerReducer,
    shippingDialog: shippingDialogReducer,
    packinglist: packinglistReducer,
    calculation: calculationReducer,
};

export const REDUCER_KEYS = [
    'activeTab',
    'projects',
    'tabs',
    'forms',
    'tables',
    'container',
    'shippingDialog',
    packinglistFeatureKey,
    f42NgrxTableFeatureKey,
    calculationFeatureKey,
];
