import { Tab } from '../tab/tab';

export const ADD_TAB = 'ADD_TAB';
export const REMOVE_TAB = 'REMOVE_TAB';
export const UPDATE_TAB = 'UPDATE_TAB';
export const CHANGE_TAB_URL = 'CHANGE_TAB_URL';

export function tabReducer(allTabs: Tab[], action) {
    switch (action.type) {
        case ADD_TAB:
            const index = allTabs.map(tab => tab.url).indexOf(action.payload.url);
            if (index === -1) {
                if (!action.payload.id) {
                    let highestId = 0;
                    allTabs.map(tab => {
                        if (tab && tab.id > highestId) { highestId = tab.id; }
                    });
                    action.payload.id = highestId + 1;
                }

                return [...allTabs, action.payload];
            }
            return allTabs;
        case REMOVE_TAB:
            return allTabs.filter(tab => {
                return tab.url !== action.payload.url;
            }
            );
        case UPDATE_TAB:
            allTabs.map(tab => {
                if (tab.url === action.payload.url) {
                    tab.suffix = tab.suffix ? tab.suffix : action.payload.suffix;
                }
            });
            return allTabs;
        case CHANGE_TAB_URL:
            allTabs.map(tab => {
                if (tab.id === action.payload.id) {
                    tab.url = action.payload.url ? action.payload.url : tab.url;
                }
            });
            return allTabs;
        default:
            return allTabs;
    }
}
