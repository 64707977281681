import { Injectable } from '@angular/core';
import { BaseService } from '@core/service/base.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@auth/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BestellpositionRequest } from '@shipping/models/bestellposition-request';
import { BestellpositionDTO } from '@shipping/models/bestellposition-dto';
import { environment } from 'environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BestellpositionResponse, ContainerDTO } from '@shipping/models/bestellposition-response';

@Injectable({
    providedIn: 'root',
})
export class PurchaseOrderPositionService extends BaseService {
    private readonly url: string = `${environment.apiBaseUrl}/purchaseOrderPosition`;

    constructor(
        protected toastr: ToastrService,
        protected translate: TranslateService,
        protected authService: AuthService,
        private httpClient: HttpClient,
    ) {
        super(toastr, translate, authService);
    }

    getBestellpositionDTO(purchaseOrderPositionRequest: BestellpositionRequest): Observable<BestellpositionResponse> {
        return this.httpClient
            .post<BestellpositionResponse>(this.url, purchaseOrderPositionRequest, {
                withCredentials: true,
            })
            .pipe(
                map(data => {
                    switch (data.orderPlacement) {
                        case true:
                            return data;
                            break;
                        case false:
                            this.showErrorMsg('generic.problems.orderPlacementNotConfirmed');
                            return null;
                            break;
                        case undefined:
                            this.showErrorMsg('generic.problems.downloadAll');
                            return data;
                            break;
                        default:
                            return data;
                            break;
                    }
                }),
                catchError(err => {
                    this.showErrorMsg('generic.problems.downloadAll');
                    return this.handleError(err);
                }),
            );
    }

    getContainerDTO(projectId: number): Observable<ContainerDTO[]> {
        return this.httpClient
            .post<ContainerDTO[]>(this.url + '/containerDTO', projectId, {
                withCredentials: true,
            })
            .pipe(
                catchError(err => {
                    this.showErrorMsg('generic.problems.downloadAll');
                    return this.handleError(err);
                }),
            );
    }

    getSingleBestellpositionDTO(bestellposititonId: number): Observable<BestellpositionDTO> {
        return this.httpClient
            .get<BestellpositionDTO>(this.url + `/` + bestellposititonId, {
                withCredentials: true,
            })
            .pipe(
                catchError(err => {
                    this.showErrorMsg('generic.problems.downloadAll');
                    return this.handleError(err);
                }),
            );
    }
}
