import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShippingPageComponent } from './pages/shipping-page/shipping-page.component';
import { AuthLoginGuard } from '@session/auth-login.guard';
import { Permissions } from '@auth/enum/permissions.enum';
import { ContainerPageComponent } from './pages/container/container-page.component';
import { PackingListPageComponent } from './pages/packing-list-page/packing-list-page.component';
import { ContainerInformationPageComponent } from './pages/container-information-page/container-information-page.component';

const PACKINGLIST = 'packinglist';
const SHIPPING_DIALOG = 'shippingDialog';
const CONTAINER = 'container';
const CONTAINERINFO = 'containerInformation';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: SHIPPING_DIALOG,
                component: ShippingPageComponent,
                canActivate: [AuthLoginGuard],
                data: {
                    tabTitle: 'general.shipping',
                    permission: Permissions.READ_SHIPPING,
                },
            },
            {
                path: CONTAINER,
                component: ContainerPageComponent,
                canActivate: [AuthLoginGuard],
                data: {
                    tabTitle: 'menu.shipping.containerSearch',
                    permission: Permissions.READ_SHIPPING,
                },
            },
            {
                path: CONTAINERINFO,
                component: ContainerInformationPageComponent,
                canActivate: [AuthLoginGuard],
                data: {
                    tabTitle: 'menu.shipping.containerInformation',
                    permission: Permissions.READ_SHIPPING,
                },
            },
            {
                path: PACKINGLIST,
                component: PackingListPageComponent,
                canActivate: [AuthLoginGuard],
                data: {
                    tabTitle: 'packinglist.title',
                    permission: Permissions.READ_SHIPPING,
                },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ShippingRoutingModule {
    static PACKINGLIST_URL = PACKINGLIST;
}
