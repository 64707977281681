import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '../app-state';
import { Store, select } from '@ngrx/store';
import { concatMap, withLatestFrom, tap } from 'rxjs/operators';
import {
    loadContainerDTO,
    loadContainerDTOsuccessfully,
    reloadLieferpositionen,
    addLieferpositionen,
} from '../actions/shipping-dialog.actions';
import { PurchaseOrderPositionService } from '@shipping/services/purchase-order-position.service';
import { of } from 'rxjs';
import {
    selectProjectIdFromBestellpositionResponse,
    selectLieferpositionRequest,
} from '../selectors/shipping.selectors';
import { ShippingPositionService } from '@shipping/services/shipping-position.service';

@Injectable()
export class ShippingDialogEffects {
    /**
     * läd die ContainerDTO für den BestellpositionResponse
     */
    loadContainerDTO$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadContainerDTO),
                concatMap(action =>
                    of(action).pipe(
                        withLatestFrom(this.store.pipe(select(selectProjectIdFromBestellpositionResponse))),
                    ),
                ),
                tap(([action, projectId]) => {
                    if (projectId) {
                        this.purchaseOrderPositionService.getContainerDTO(projectId).subscribe(data => {
                            this.store.dispatch(loadContainerDTOsuccessfully({ containerDTOs: data }));
                        });
                    }
                }),
            ),
        { dispatch: false },
    );

    /**
     * läd die Lieferpositionen mit den LieferpositionsRequest
     */
    reloadLieferpositionen$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(reloadLieferpositionen),
                concatMap(action =>
                    of(action).pipe(withLatestFrom(this.store.pipe(select(selectLieferpositionRequest)))),
                ),
                tap(([action, request]) => {
                    if (request) {
                        this.shippingPositionService.getLieferpositionDTOs(request).subscribe(data => {
                            this.store.dispatch(
                                addLieferpositionen({
                                    lieferpositionen: data,
                                }),
                            );
                        });
                    }
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private purchaseOrderPositionService: PurchaseOrderPositionService,
        private shippingPositionService: ShippingPositionService,
        private store: Store<AppState>,
    ) {}
}
