import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageTitleService } from '@core/page-title/page-title.service';
import { AppState } from '@shared/store/app-state';
import { Store, select } from '@ngrx/store';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectProjectDTO, selectContainerDTO } from '@shared/store/selectors/packinglist.selectors';
import { ProjectDTO, ContainerDTO } from '@shipping/models/bestellposition-response';
import { loadCustomerShipModeAndDivison, loadKartonsIfNotExists } from '@shared/store/actions/packinglist.actions';
import { CustomerService } from '@customer/customer.service';
import { CustomerDivsion } from '../../../customer/customersDivision';
import { CustomerShippingMode } from '@customer/customerShippingModes';

@Component({
    templateUrl: './packing-list-page.component.html',
    styleUrls: ['./packing-list-page.component.scss'],
})
export class PackingListPageComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    containerDTO: ContainerDTO;
    projectDTO: ProjectDTO;
    customerDivisions: CustomerDivsion[];
    customerShipModes: CustomerShippingMode[];

    constructor(
        private pageTitleService: PageTitleService,
        protected store: Store<AppState>,
        private customerService: CustomerService,
    ) {
        combineLatest(this.store.pipe(select(selectProjectDTO)), this.store.pipe(select(selectContainerDTO)))
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(data => {
                if (data) {
                    this.projectDTO = data[0];
                    this.containerDTO = data[1];

                    customerService.getCustomerDivisions('' + this.projectDTO.customer_id).subscribe(dataDivisions => {
                        this.customerDivisions = dataDivisions;
                    });

                    customerService
                        .getCustomerShippingModes('' + this.projectDTO.customer_id)
                        .subscribe(dataShipModes => {
                            this.customerShipModes = dataShipModes;
                        });

                    if (this.containerDTO && this.projectDTO && this.projectDTO.id) {
                        this.store.dispatch(
                            loadKartonsIfNotExists({
                                projectId: this.projectDTO.id,
                                containerId: this.containerDTO.id,
                                kundenauftragsnummer: this.containerDTO.kundenauftragsnummer,
                            }),
                        );
                    }
                }
            });
    }

    ngOnInit() {
        this.pageTitleService.setTitle('packinglist.title');
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
